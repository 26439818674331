import { Host } from "./Constant";

export function buildUrl(path) {
    return Host + path;
}

export function getUrl(path) {
    return buildUrl("/REST/") + path;
}

export function formatMoney(num, decimals = 2) {
    return parseFloat(parseFloat(num).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: decimals });
}

export function parseURLParam() {
    const parse = window.location.href.split("?");
    let param = [];
    if(parse.length > 1) {
        const data = parse[1].split("&");
        data.forEach(d => {
            const s = d.split("=");
            param[s[0]] = s[1];
        });
    }
    return param;
}

export function getTypeColor(info) {
    let color = 'green-600'
    if(info.type === "Wholesaler") {
        color = 'amber-500';
    }
    return { text: 'text-' + color, bg: 'bg-' + color };
}

export function getDeliveryStatusColor(status) {
    // SHIPPING = ORDER PLACED | PREPARING TO SHIP | IN TRANSIT | OUT FOR DELIVERY | DELIVERED | CANCELLED
    // PICKUP = ORDER PLACED | PREPARING | READY FOR PICKUP | DELIVERED | CANCELLED
    let color = 'orange-500';
    if(status === "PREPARING" || status === "PREPARING TO SHIP") {
        color = 'yellow-500';
    }else if(status === "READY FOR PICKUP" || status === "IN TRANSIT") {
        color = 'blue-500';
    }else if(status === "OUT FOR DELIVERY") {
        color = 'sky-500';
    }else if(status === "DELIVERED") {
        color = 'emerald-500';
    }else if(status === "CANCELLED" || status === "DECLINED") {
        color = 'red-600';
    }
    return { text: 'text-' + color, bg: 'bg-' + color };
}