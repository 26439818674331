import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { Themes } from "../../../common/Constant";
import { formatMoney } from "../../../common/Utils";
import { GetProducts, GetDiscount } from "../../../common/Request";
import { Navigation } from "../Navigation";
import { CheckoutModal } from "../component/CheckoutModal";
import { useStoreState } from "../../../common/Store";
import photo from "../../../assets/img/image.svg";
import check from "../../../assets/img/check-white.svg";

export const RenewPurchase = () => {

    const userInfo = useStoreState("userinfo");
    const [ data, setData ] = useState([]);
    const [ showCheckout, setShowCheckout ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ discount, setDiscount ] = useState(0);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ shipping, setShipping ] = useState(0);
    const [ totalPoints, setTotalPoints ] = useState(0);
    const [ disabled, setDisabled ] = useState(true);
    const deliveryRef = useRef();

    const changeQty = (e, prid, index) => {
        let exist = false;
        let obj = data;
        for(let i = obj.length - 1; i > -1; i--) {
            if(parseInt(prid) === parseInt(obj[i].prid)) {
                exist = true;
                obj[i].qty = parseInt(e.target.value);
                if(parseInt(e.target.value) === 0) {
                    obj.splice(i, 1);
                }
            }
        }
        if(!exist) {
            obj.push({
                "prid":products[index].prid,
                "name":products[index].name,
                "free":products[index].free,
                "price":products[index].price,
                "points":products[index].points,
                "qty":parseInt(e.target.value)
            })
        }
        setData(obj);
        calculate();
    }

    const calculate = () => {
        let sub = 0, points = 0, freeShipping = true;
        data.forEach(d => {
            sub += parseFloat(d.price) * parseInt(d.qty);
            points += parseFloat(d.points) * parseInt(d.qty);
            if((parseInt(d.qty) / parseInt(d.free)).toString().indexOf(".") > -1) {
                freeShipping = false;
            }
        });
        setSubtotal(sub);
        setTotalPoints(points);
        setDisabled(parseInt(userInfo.mrp) > points);
        if(deliveryRef.current.value === "Pickup") {
            setShipping(0);
        }else{
            setShipping(freeShipping ? 0 : parseFloat(userInfo.shipping));
        }
    }

    useEffect(() => {
        GetProducts(res => {
            setProducts(res);
        });
        GetDiscount({ mid: userInfo.mid }, res => {
            setDiscount(parseFloat(res.discount));
        });
    }, [ userInfo ]);

    return (
        <div>
            <Navigation />
            <div className="w-screen block m-auto">
                <h1 className="p-4 text-white text-xl text-center font-bold">RENEW ACCOUNT</h1>
                <div className="grid p-4 pt-0 grid-cols-1 md:grid-cols-2/1">
                    <div className="grid gap-4 grid-cols-1 p-4 pb-20 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1">
                        {
                            products.map((d, i) => (
                                <div className="relative bg-slate-100 pb-2" key={ "product_" + d.prid }>
                                    <h5 className="absolute right-0 top-0 text-white bg-amber-600 text-center font-bold w-24 italic">{ d.points } pts.</h5>
                                    <img src={ d.photo ? d.photo : photo } className="w-full h-48 bg-white object-contain" alt="productphoto" />
                                    <h5 className="text-slate-700 text-lg px-2 font-bold">{ d.name }</h5>
                                    <h5 className="text-green-600 text-xs px-2">Free shipping every { d.free } qty</h5>
                                    <h5 className="px-2 text-red-600 italic">
                                        <span className="line-through">&#8369;{ formatMoney(d.price) }</span>
                                        <span className="float-right">-{ (discount * 100) }%</span>
                                    </h5>
                                    <h5 className="text-amber-600 px-2 text-2xl font-bold mb-2">&#8369;{ formatMoney(parseFloat(d.price) - (parseFloat(d.price) * discount)) }</h5>
                                    <h5 className="px-2 text-right">
                                        QTY. <input type="number" min="0" defaultValue="0" className={ Themes.InputSmall } onChange={ (e) => { changeQty(e, d.prid, i) } } />
                                    </h5>
                                </div>
                            ))
                        }
                    </div>
                    <div className="p-4">
                        <div className="bg-white p-4">
                            <h1 className="text-center text-xl font-bold text-slate-700">Checkout</h1>

                            {
                                data.length > 0 ? 
                                    data.map(d => (
                                        <div className="py-2 border-b-2 border-slate-700" key={ "renew_" + d.prid + "_" + moment().format("x") }>
                                            <h5 className="font-bold">{ d.name }</h5>
                                            <h5 className="text-sm">
                                                &#8369;{ formatMoney(d.price) }
                                                <div className="float-right">
                                                    Qty: <input type="number" onChange={ (e) => { changeQty(e, d.prid) } } className="w-20 text-center rounded-sm font-bold" defaultValue={ d.qty } step="1" min="0" />
                                                </div>
                                            </h5>
                                            <h5 className="text-black">
                                                <span className="italic text-xs">* free shipping for every <b>{ d.free }</b> qty</span>
                                                <b className="float-right text-amber-600">&#8369;{ formatMoney(parseFloat(d.price) * d.qty) }</b>
                                            </h5>
                                        </div>
                                    ))
                                : <h5 className="text-center m-4">No products to checkout</h5>
                            }
                            <div className="text-center mt-4">
                                <h5 className="text-right text-sm mb-4 border-b-2 border-slate-700 pb-2 mx-4">Delivery Method: 
                                    <select ref={ deliveryRef } onChange={ () => { calculate() } } className={ Themes.Input }>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Pickup">Pickup</option>
                                    </select>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Total Points: 
                                    <span className="text-slate-700 font-bold text-lg ml-2">{ totalPoints } pts.</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Subtotal: 
                                    <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(subtotal) }</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Discount: 
                                    <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(subtotal * discount) } <i>({ discount * 100 }%)</i></span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Shipping Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(shipping) }</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm mb-4">Total: 
                                    <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(subtotal - (subtotal * discount) + shipping) }</span>
                                </h5>
                                {
                                    !disabled ?
                                        <button type="button" className={ Themes.HeaderButton } onClick={ () => { setShowCheckout(true) } }>
                                            <img src={ check } className={ Themes.HeaderButtonImage } alt="checkicon" />
                                            Checkout
                                        </button>
                                    : null
                                }
                                
                                
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
            <CheckoutModal data={ data } type="Renew" show={ showCheckout } onClose={ () => { setShowCheckout(false) } } />
        </div>
    )
}