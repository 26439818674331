import { Navigation } from "./Navigation";
import { PurchaseTable } from "./tables/PurchaseTable";

export const AdminRepeatPurchase = () => {

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto p-8">
                <PurchaseTable type={ 2 } />
            </div>
        </div>
    )
}