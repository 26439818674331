import { createStore } from "react-hooks-global-state";

const reducer = (state, action) => {
	state[action.type] = action.data;
}

const initialState = {
    page: "login",
    userinfo: null,
    updatecart: 0,
    alert: {
        show: false,
        title: "Warning",
        message: "Sed libero enim sed faucibus turpis in eu mi bibendum."
    },
}

export const { dispatch, useStoreState } = createStore(reducer, initialState);