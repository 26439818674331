import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { formatMoney } from "../../../common/Utils";
import { Themes } from "../../../common/Constant";
import { ShowAlert } from "../../UI/Alert";
import { GetWallet, RewardProducts, GetMemberPartners, GetMyRewards, RedeemRewards } from "../../../common/Request";
import { Navigation } from "../Navigation";
import { useStoreState } from "../../../common/Store";
import check from "../../../assets/img/check-white.svg";

export const ClaimRewards = () => {

    const userInfo = useStoreState("userinfo");
    const [ products, setProducts ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ myRewards, setMyRewards ] = useState([]);
    const [ partners, setPartners ] = useState([]);
    const [ wallet, setWallet ] = useState();
    const [ selectedPartner, setSelectedPartner ] = useState("None");
    const [ itemIndex, setItemIndex ] = useState(0);
    const [ cart, setCart ] = useState([]);
    const [ totalPoints, setTotalPoints ] = useState(0);
    const [ totalPrice, setTotalPrice ] = useState(0);
    const methodRef = useRef();

    const categoryFilter = (partner) => {
        setSelectedPartner(partner);
        partner === "None" ? setData([]) : setData(products.filter(d => d.category === partner));
    }

    const onChangeQty = (e, rdid) => {
        const value = parseInt(e.target.value);
        let obj = cart, exist = false;
        let points = 0, amount = 0;

        obj.forEach((o, i) => {
            if(rdid === o.rdid) {
                exist = true;
                o.qty = value;
                if(o.qty < 1) {
                    obj.splice(i, 1);
                }
            }
        });

        if(!exist && value > 0) {
            obj.push({ rdid: rdid, qty: value });
        }

        obj.forEach(o => {
            const item = data.find(p => p.rdid === o.rdid);
            amount += o.qty * item.price;
            points += o.qty * item.points;
        });
        
        setTotalPoints(points);
        setTotalPrice(amount);
        setCart(obj);
    }

    const submitRedemption = (e) => {
        const type = methodRef.current.value;
        if(type === "Points Reward") {
            if(totalPoints > wallet?.rpb) {
                ShowAlert("Failed", "You don't enough Reward Points to send this redemption.");
                e.preventDefault();
                return;
            }
        }else if(type === "Wallet Balance") {
            if(totalPoints > wallet?.amount) {
                ShowAlert("Failed", "You don't enough balance from your wallet to send this redemption.");
                e.preventDefault();
                return;
            }
        }

        RedeemRewards({
            rdids: JSON.stringify(cart),
            mid: userInfo.mid,
            type: type
        }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "Please proceed to cashier to claim your rewards.");
                GetMyRewards({ mid: userInfo.mid }, res => setMyRewards(res) );
                GetWallet({ mid: userInfo.mid }, res => setWallet(res));
                setItemIndex(itemIndex + 1);
                setTotalPoints(0);
                setTotalPrice(0);
                setCart([]);
            }else{
                ShowAlert(res.t, res.m);
            }
        })
        e.preventDefault();
    }

    useEffect(() => {
        GetWallet({ mid: userInfo.mid }, res => setWallet(res));

        RewardProducts(res => {
            setProducts(res);
            setData([]);
        });

        GetMyRewards({ mid: userInfo.mid }, res => setMyRewards(res));
        GetMemberPartners(res => setPartners(res))
    }, [ userInfo.mid ]);

    return (
        <div>
            <Navigation />
            <div className="bg-white w-screen block m-auto">
                <br/>
                <div className="p-4">
                    <div className="grid grid-cols-2 gap-2 md:grid-cols-4">
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen + " from-rose-600 to-rose-700" }>Reward Points Bonus</h3>
                            <h1 className={ Themes.DashboardAmount + " text-rose-700" }>
                                { wallet?.rpb | 0 }  
                            </h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Wallet Balance</h3>
                            <h1 className={ Themes.DashboardAmount }>
                                { formatMoney(wallet?.amount) }  
                            </h1>
                        </div>
                    </div>
                    
                    <h1 className="font-bold text-rose-700 text-xl pb-2">Redemption History</h1>
                    <div className="overflow-x-auto">
                        <table className="w-full min-w-[700px] text-slate-700 text-xs table-auto border-collapse border border-slate-400">
                            <thead>
                                <tr>
                                    <th className="border border-slate-300 p-1">Created</th>
                                    <th className="border border-slate-300 p-1">Items</th>
                                    <th className="border border-slate-300 p-1">Total</th>
                                    <th className="border border-slate-300 p-1">Method</th>
                                    <th className="border border-slate-300 p-1">Status</th>
                                </tr>
                            </thead>
                            {
                                myRewards.length > 0 ?
                                    <tbody>
                                        {
                                            myRewards.map(r => (
                                                <tr>
                                                    <td className="border border-slate-300 p-1 text-center">{ moment(r.created).format("MMM DD, YYYY") }</td>
                                                    <td className="border border-slate-300 pl-4">
                                                        {
                                                            r.products.map(p => (
                                                                <div>{ p.qty }&times; { p.name }</div>
                                                            ))
                                                        }
                                                    </td>
                                                    <td className="border border-slate-300 p-1 text-center">{ r.type === "Reward Points" ? r.points + " pts" : <div>&#8369;{ formatMoney(r.total) }</div> }</td>
                                                    <td className="border border-slate-300 p-1 text-center">{ r.type }</td>
                                                    <td className="border border-slate-300 p-1 text-center"><b className={ "text-" + (r.status === "PENDING" ? "amber" : "green") + "-600" }>{ r.status }</b></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                : 
                                <caption className="caption-bottom p-2 border border-slate-300 font-bold">
                                    No rewards claimed
                                </caption>
                            }
                        </table>
                    </div>
                    
                </div>
                <br />
                <hr />
                <br />
                <div className="p-4 block md:flex" key={ "redeem-products-" + itemIndex }>
                    <div className="grow w-full p-2">
                        <h1 className="font-bold text-rose-700 text-xl pb-2">Select Partner Merchant</h1>
                        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 items-center">
                            {
                                partners.map(p => (
                                    <div className={ "inline-block m-4 cursor-pointer " + (selectedPartner === p.partner ? "" : "grayscale") } onClick={ () => { categoryFilter(p.partner) } } key={ "partner-" + p.ptid }>
                                        <img src={ p.photo } className="h-auto" alt="partner-photo1" />
                                    </div>
                                ))
                            }
                        </div>
                        <div className="grid gap-4 grid-cols-2 pb-20 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2">
                            {
                                data.map(p => (
                                    <div className="relative inline-block mb-2" key={ "item-" + p.rdid }>
                                        <img src={ p.photo } alt={ p.photo } className="w-full h-32 md:h-48 object-cover bg-slate-100" />
                                        <h5>{ p.name }</h5>
                                        <h5 className="text-xs">{ p.category }</h5>
                                        <h5 className="text-rose-700 italic font-bold">{ p.points }pts</h5>
                                        <h5 className="text-green-700 italic font-bold">&#8369; { formatMoney(p.price) }</h5>
                                        <div className="text-right">
                                            <input type="number" onChange={ e => onChangeQty(e, p.rdid) } step="1" min="0" placeholder="Qty." className="border-2 border-[crimson] w-32 px-4 my-2 rounded-full" />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="w-full md:w-[400px]">
                        <form onSubmit={ submitRedemption }>
                            <h1 className="font-bold text-rose-700 text-xl pb-2">Redemption Details</h1>
                            <h5 className="text-sm font-bold text-slate-600 text-center">ITEMS</h5>
                            <hr className="my-4"/>
                            <h5 className="text-sm font-bold text-slate-600 text-center">TOTAL</h5>
                            <h1 className="text-[crimson] text-right font-bold text-xl">{ totalPoints } <i>pts.</i></h1>
                            <h5 className="text-sm text-slate-600 text-right">or</h5>
                            <h1 className="text-[green] text-right font-bold text-xl">&#8369; { formatMoney(totalPrice) }</h1>
                            <i className="my-2 text-sm text-red-600">* Redemption of products is available for pickup only.</i>
                            <select ref={ methodRef } className="bg-slate-700 text-white w-full px-2 py-1 font-bold my-4" required>
                                <option value="" hidden>Method</option>
                                <option value="Reward Points">Reward Points</option>
                                <option value="Wallet Balance">Wallet Balance</option>
                                <option value="Cash">Cash</option>
                            </select>
                            {
                                cart.length > 0 ?
                                    <button type="submit"
                                        className={ "block mx-auto transition duration-300 text-sm text-center h-10 text-white px-4 py-2 my-4 bg-amber-500 border-2 border-amber-500 rounded-full font-medium hover:bg-slate-700 hover:border-slate-700" } 
                                        >
                                        <img src={ check } className={ Themes.HeaderButtonImage} alt="checkicon" />
                                        REDEEM REQUEST
                                    </button>
                                :
                                <button type="button"
                                    className={ "block mx-auto transition duration-300 text-sm text-center h-10 text-white px-4 py-2 my-4 border-2 rounded-full font-medium bg-slate-300 border-slate-300" } 
                                    >
                                    <img src={ check } className={ Themes.HeaderButtonImage} alt="checkicon" />
                                    REDEEM REQUEST
                                </button>
                            }
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}