export const Host = "";
export const Site = "https://leiprime.com";

export const Themes = {
    InputIcon : "absolute left-2 top-2 w-6",
    InputButtonIcon : "transition duration-200 absolute left-2 top-2 w-6 bg-amber-500 rounded-sm cursor-pointer hover:bg-slate-700",
    InputButtonRight : "transition duration-200 absolute top-2 right-2 text-xs text-white font-bold bg-green-500 px-4 py-1 rounded-full hover:bg-slate-700",
    InputField : "w-full px-10 py-2 border-b-2 border-slate-300 mb-2",
    ImageButton : "transition duration-200 w-8 inline-block mx-1 bg-slate-200 rounded-full p-1 cursor-pointer hover:bg-amber-300",
    FilledButton : "transition duration-300 w-full text-center text-white p-2 my-2 bg-amber-500 border-2 border-amber-500 rounded-full font-medium hover:bg-slate-700 hover:text-white hover:border-slate-700",
    OutlineButton : "transition duration-300 w-full text-center text-slate-700 p-2 my-2 bg-transparent border-2 border-slate-700 rounded-full font-medium hover:bg-slate-700 hover:text-white",
    GreenButton : "transition duration-300 w-full text-center text-green-600 p-2 my-2 bg-transparent border-2 border-green-600 rounded-full font-medium hover:bg-green-600 hover:text-white",
    RedButton : "transition duration-300 w-full text-center text-red-600 p-2 my-2 bg-transparent border-2 border-red-600 rounded-full font-medium hover:bg-red-600 hover:text-white",
    HeaderButton : "transition duration-300 text-sm text-center h-10 text-white px-4 py-2 mx-4 bg-amber-500 border-2 border-amber-500 rounded-full font-medium hover:bg-slate-700 hover:text-white hover:border-slate-700",
    HeaderButtonImage : "h-4 inline-block mr-2 scale-150 relative -top-0.5",
    Italic : "italic text-gray-600 mb-1",
    Header : "text-lg bg-amber-500 p-2 py-1 text-white mb-2 text-center font-bold relative",
    SubPanel : "bg-slate-200 rounded-md",
    SubHeader : "font-bold bg-green-700 text-white px-4 py-1 rounded-t-md",
    SubBody : "text-xl text-slate-900 font-bold px-4 py-2",
    BigText : "",
    DashboardAmount : "font-bold text-3xl text-slate-700 px-2 py-5 relative",
    DashboardButton : "text-sm absolute bottom-0 right-0 bg-rose-700 text-white px-2 py-0.5 rounded-l-lg",
    PanelHeadAmber : "bg-gradient-to-b from-amber-500 to-amber-600 text-white font-bold text-md p-2 drop-shadow-md",
    PanelHeadGreen : "bg-gradient-to-b from-green-500 to-green-600 text-white font-bold text-sm p-2 drop-shadow-md",
    AdminPanel : "border-2",
    AdminPanelHead : "text-white p-2 font-bold text-center",
    AdminPanelBody : "text-white text-2xl font-bold text-center py-4",
    AdminBox : "bg-white text-center py-4 cursor-pointer",
    AdminBoxLabel : "text-slate-700 font-bold mb-2",
    AdminBoxMessage : "text-sky-600 font-bold text-4xl",
    Input : "py-1 px-2 ml-2 text-slate-700 font-bold w-32",
    InputSmall : "py-1 px-2 ml-2 text-slate-700 font-bold w-16",
}