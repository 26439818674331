import moment from "moment";
import { useStoreState } from "../../../common/Store";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { formatMoney } from "../../../common/Utils";
import { GetHistory } from "../../../common/Request";

export const HistoryTable = ({ mid = 0 }) => {

    const userInfo = useStoreState("userinfo");

    const columns = [
        {
            name: 'No.',
            selector: (row, i) => i + 1,
            sortable: true,
        },
        {
            name: 'From',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
        },
        {
            name: 'Details',
            selector: row => <div>
                {
                    row.details ? 
                        row.type === "Group Consumer Bonus" ?
                            <span>Left: { JSON.parse(row.details).l } Right: { JSON.parse(row.details).r }</span>
                        : <span>{ row.details }</span>
                    : null
                }
            </div>,
        },
        {
            name: 'Amount/Points',
            selector: row => <div>{ 
                    row.type === "Reward Points Bonus" ? row.amount + "pts" : 
                    <span>&#8369; { formatMoney(row.amount) }</span> }
                </div>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "Earned" ? "text-green-600" : "text-red-600" }>{ row.status }</b>,
        },
        {
            name: 'Created',
            selector: row => moment(row.created).format("MMM DD, YYYY h:mma"),
        },
    ];

    const [ data, setData ] = useState([]);

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block">Earnings</h1>
            </div>
        )
    }, []);

    useEffect(() => {
        GetHistory({ mid: mid > 0 ? mid : userInfo.mid }, res => {
            setData(res);
        });
    }, [ userInfo, mid ])

    return (
        <div className="w-full mb-4">
            <DataTable
                title={ mid > 0 ? null : titleComponent }
                pagination
                columns={ columns }
                data={ data }
            />
        </div>
        
    )
}