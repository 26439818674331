import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Themes } from "../../../common/Constant";
import { formatMoney } from "../../../common/Utils";
import { GetAdminPackagePurchase, GetAdminRepeatPurchase } from "../../../common/Request";
import { PurchaseForm } from "../form/PurchaseForm";
import searchIcon from "../../../assets/img/search.svg";
import editIcon from "../../../assets/img/edit.svg";
import { useStoreState } from "../../../common/Store";

export const PurchaseTable = ({ type = 1 }) => { // 1 = Package | 2 = Repeat

    const userInfo = useStoreState("userinfo");
    const [ pid, setPid ] = useState(0);
    const [ showDetails, setShowDetails ] = useState(false);

    const showPurchaseDetails = (data) => {
        setPid(data);
        setShowDetails(true);
    }

    const hidePurchaseDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            if(type === 1) {
                GetAdminPackagePurchase(res => {
                    setData(res);
                })
            }else{
                GetAdminRepeatPurchase(res => {
                    setData(res);
                });
            }
        }
    }

    let columns = [
        {
            name: 'Ref #',
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Package',
            selector: row => <div>
                {
                    parseInt(row.renew) > 0 ? <b className="text-amber-600">Renew<br /></b> : null
                }
                {
                    <b>{ row.package }</b>
                }
            </div>,
            sortable: true,
        },
        {
            name: 'Total Amount',
            selector: row => <div>&#8369; { formatMoney(row.total) }</div>,
            sortable: true,
        },
        {
            name: 'Cashier',
            selector: row => row.cashier,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "COMPLETED" ? "text-green-600" : row.status === "DECLINED" ? "text-red-600" : "text-amber-500" }>{ row.status }</b>,
        },
        {
            name: 'Action',
            selector: row => <img src={ editIcon } onClick={ () => { showPurchaseDetails(row.pid); } } className={ Themes.ImageButton } alt="editicon" />
        },
        {
            name: 'Updated',
            selector: row => moment(row.updated).format("MMM DD, YYYY"),
        },
    ];

    if(type === 2) {
        columns.splice(2, 1);
    }

    const [ data, setData ] = useState([]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block">{ type === 1 ? "Package" : "Repeat" } Purchase</h1>
            </div>
        )
    }, [ type ]);

    const subHeaderComponentMemo = useMemo(() => {
		return (
            <div>
                <div className="relative">
                    <img src={ searchIcon } className={ Themes.InputIcon } alt="searchicon" />
                    <input type="text" className={ Themes.InputField } placeholder="Filter By Name" value={ filterText } onChange={ (e) => { setFilterText(e.target.value) } } />
                </div>
            </div>
		);
	}, [filterText]);

    useEffect(() => {
        if(type === 1) {
            GetAdminPackagePurchase(res => {
                setData(res);
            })
        }else{
            GetAdminRepeatPurchase(res => {
                setData(res);
            });
        }
    }, [ type, userInfo ])

    return (
        <div className="w-full mb-4">
            <DataTable
                title={ titleComponent }
                pagination
                columns={ columns }
                data={ filteredItems }
                subHeader
                subHeaderComponent={ subHeaderComponentMemo }
            />
            <PurchaseForm pid={ pid } show={ showDetails } hide={ hidePurchaseDetails } />
        </div>
        
    )
}