import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../../../common/Constant";
import { AdminAddRewardItem, AdminEditRewardItem, GetPartners } from "../../../common/Request";
import { ShowAlert } from "../../UI/Alert";
import pouchIcon from "../../../assets/img/pouch.svg";
import infoIcon from "../../../assets/img/info.svg";
import pointsIcon from "../../../assets/img/points.svg";
import priceIcon from "../../../assets/img/price.svg";
import photoIcon from "../../../assets/img/photo.svg";
import closeIcon from "../../../assets/img/white-x.svg";
import { useState, useRef, useEffect } from "react";

export const RewardForm = ({ isAdd = false, data = {}, show = false, hide }) => {

    const nameRef = useRef();
    const catRef = useRef();
    const pointsRef = useRef();
    const priceRef = useRef();
    const photoRef = useRef();
    const [ photo, setPhoto ] = useState(null);
    const [ partners, setPartners ] = useState([]);

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const onSubmit = (e) => {
        if(isAdd) {
            AdminAddRewardItem({
                category: catRef.current.value,
                name: nameRef.current.value,
                points: pointsRef.current.value,
                price: priceRef.current.value,
                photo: photoRef.current.files[0]
            }, res => {
                if(res.t === "Success") {
                    photoRef.current.value = "";
                    ShowAlert("Success", "New reward item was added.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            })
        }else{
            AdminEditRewardItem({
                rdid: data.rdid,
                category: catRef.current.value,
                name: nameRef.current.value,
                points: pointsRef.current.value,
                price: priceRef.current.value,
                photo: photoRef.current.files[0]
            }, res => {
                if(res.t === "Success") {
                    photoRef.current.value = "";
                    ShowAlert("Success", "Changes was saved!");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            })
        }
        e.preventDefault();
    }

    const onChangePhoto = (e) => {
        const reader = new FileReader();
        reader.onload = function(evt)  {
            setPhoto(evt.target.result);
         }
         reader.readAsDataURL(e.target.files[0]);
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            });
        }
        if(!isAdd) {
            if(data.photo !== null) {
                setPhoto(data.photo);
            }
            nameRef.current.value = data.name;
            catRef.current.value = data.category;
            pointsRef.current.value = data.points;
            priceRef.current.value = data.price;
        }else{
            nameRef.current.value = catRef.current.value = pointsRef.current.value = priceRef.current.value = "";
        }
        GetPartners(res => {
            setPartners(res);
        })
    }, [ show, data, anim, isAdd ])

    return (
        <div className={ "fixed z-50 inset-0 w-screen h-screen bg-slate-700/50 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    {
                        isAdd ? "Add New Reward Item" : "Edit Reward Item"
                    }
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2">
                    { !isAdd ? <h5 className="text-sm font-bold">Item Name:</h5> : null }
                    <div className="relative">
                        <img src={ pouchIcon } className={ Themes.InputIcon } alt="pouchicon" />
                        <input ref={ nameRef } type="text" className={ Themes.InputField } placeholder="Item Name" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Category:</h5> : null }
                    <div className="relative">
                        <img src={ infoIcon } className={ Themes.InputIcon } alt="infoicon" />
                        <select ref={ catRef } className={ Themes.InputField } required>
                            <option value="">Select Merchant Partner</option>
                            {
                                partners.map((p, i) => (
                                    <option value={ p.partner } key={ "partner-" + i }>{ p.partner }</option>
                                ))
                            }
                        </select>
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Points:</h5> : null }
                    <div className="relative">
                        <img src={ pointsIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input ref={ pointsRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Points" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Price:</h5> : null }
                    <div className="relative">
                        <img src={ priceIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input ref={ priceRef } type="number" inputMode="numeric" className={ Themes.InputField } placeholder="Price" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Item Photo:</h5> : null }
                    <div className="relative">
                        <img src={ photoIcon } className={ Themes.InputIcon } alt="photoicon" />
                        <input ref={ photoRef } type="file" accept="image/*" onChange={ (e) => { onChangePhoto(e) } } className={ Themes.InputField } placeholder="Product Photo" />
                    </div>
                    {
                        photo ? <img src={ photo } className="w-32 h-32 m-auto mb-4 object-contain" alt="previewproduct" /> : null
                    }
                    <button type="submit" className={ Themes.FilledButton }>{ isAdd ? "Submit" : "Save Changes" }</button>
                </form>
            </animated.div>
        </div>
    )
}