import moment from "moment";
import DataTable from "react-data-table-component";
import { GetAdminMemberHistory } from "../../common/Request";
import { formatMoney } from "../../common/Utils";
import { Navigation } from "./Navigation";
import { useEffect, useState } from "react";

export const AdminMemberHistory = () => {

    const [ members, setMembers ] = useState([]);
    const [ types, setTypes ] = useState([]);
    const [ data, setData ] = useState([]);

    const columns = [
        {
            name: 'Created',
            selector: row => moment(row.created).format("MMM DD, YYYY h:mma"),
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => members[parseInt(row.mid)],
            sortable: true,
        },
        {
            name: 'From',
            selector: row => members[parseInt(row.mmid)],
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => types[row.type],
            sortable: true,
        },
        {
            name: 'Details',
            selector: row => <div>
                {
                    row.details ? 
                        types[row.type] === "Group Consumer Bonus" ?
                            <span>Left: { JSON.parse(row.details).l } Right: { JSON.parse(row.details).r }</span>
                        : <span>{ row.details }</span>
                    : null
                }
            </div>,
        },
        {
            name: 'Amount/Points',
            selector: row => <div>{ 
                    types[row.type] === "Reward Points Bonus" ? row.amount + "pts" : 
                    <span>&#8369; { formatMoney(row.amount) }</span> }
                </div>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "Earned" ? "text-green-600" : "text-red-600" }>{ row.status }</b>,
        },
    ];

    useEffect(() => {
        GetAdminMemberHistory(res => {
            setMembers(res.Members);
            setTypes(res.Type);
            setData(res.History);
        })
    }, []);

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen p-8">
                <DataTable
                    title="Member History"
                    pagination
                    columns={ columns }
                    data={ data }
                    subHeader
                    // subHeaderComponent={ subHeaderComponentMemo }
                />
            </div>
        </div>
    )
}