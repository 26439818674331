import { useState } from "react"

export const TabPanel = ({ tabs = [], onChange = null }) => {

    const [ index, setIndex ] = useState(0);

    const click = (i) => {
        setIndex(i);
        if(onChange) onChange(i);
    }

    return (
        <div className="grid grid-cols-2 gap-1 justify-end md:flex">
            {
                tabs.map((t, i) => (
                    <div onClick={ () => { click(i) } }
                        className={ "px-2 py-1 text-white min-w-28 text-center cursor-pointer " + (index === i ? "bg-amber-500" : "bg-slate-600") }
                        key={ "tab_" + t + "_" + i }
                        >
                        { t }
                    </div>
                ))
            }
        </div>
    )
}