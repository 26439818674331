import { Navigation } from "./Navigation";
import { MemberCart } from "./MemberCart";
import { PurchaseTable } from "./tables/PurchaseTable";
import { HistoryTable } from "./tables/HistoryTable";

export const MemberHistory = () => {

    return (
        <div>
            <Navigation />
            <MemberCart />
            <div className="m-4">
                <PurchaseTable />
                <HistoryTable />
            </div>
        </div>
    )
}