import { Navigation } from "./Navigation";
import { ProductTable } from "./tables/ProductTable";
import { PackageTable } from "./tables/PackageTable";

export const AdminInventory = () => {

    return (
        <div className="relative flex w-screen">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto p-8">
                <ProductTable />
                <PackageTable />
            </div>
        </div>
    )

}