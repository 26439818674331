import moment from "moment";
import { useSpring, animated  } from "@react-spring/web";
import { GetProducts, GetDiscount, GetAdminSettings, AdminManualCheckout } from "../../../common/Request";
import { formatMoney } from "../../../common/Utils";
import { Themes } from "../../../common/Constant";
import { ShowAlert } from "../../UI/Alert";
import { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/img/white-x.svg";

export const CartForm = ({ data, show = false, hide }) => {

    const [ time, setTime ] = useState(moment().format("X"));
    const [ cart, setCart ] = useState([]);
    const [ products, setProducts ] = useState([]);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ discount, setDiscount ] = useState(0);
    const [ shipping, setShipping ] = useState(0);
    const [ shippingFee, setShippingFee ] = useState(0);
    const deliveryRef = useRef();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = () => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide();
            }
        })
    }

    const changeQty = (e, prid) => {
        let obj = cart, exist = false;
        for(let i = obj.length - 1; i > -1; i--) {
            if(parseInt(prid) === parseInt(obj[i].prid)) {
                obj[i].qty = parseInt(e.target.value);
                if(parseInt(e.target.value) === 0) {
                    obj.splice(i, 1);
                }
                exist = true;
            }
        }
        if(!exist) {
            obj.push({ prid: prid, qty: parseInt(e.target.value) });
        }
        setCart(obj);
        calculate();
    }

    const calculate = () => {
        let sub = 0, freeShipping = true;
        cart.forEach(d => {
            let freeship = 0, price = 0;
            products.forEach(p => {
                if(parseInt(p.prid) === parseInt(d.prid)) {
                    freeship = parseInt(p.free);
                    price = parseFloat(p.price);
                }
            });
            sub += price * parseInt(d.qty);
            if((parseInt(d.qty) / freeship).toString().indexOf(".") > -1) {
                freeShipping = false;
            }
        });
        setSubtotal(sub);
        if(deliveryRef.current.value === "Pickup") {
            setShipping(0);
        }else{
            setShipping(freeShipping ? 0 : shippingFee);
        }
    };

    const onSubmit = (e) => {
        if(subtotal > 0) {
            AdminManualCheckout({
                mid: data.mid,
                cart: JSON.stringify(cart),
                discount: discount,
                shipping: shipping,
                subtotal: subtotal,
                total: subtotal - (subtotal * discount) + shipping,
                delivery: deliveryRef.current.value
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Checkout", "Successfully added to Repeat Purchase for payment.");
                    close();
                }else{
                    ShowAlert(res.t, res.m);
                }
            })
        }else{
            ShowAlert("Checkout Failed", "No products for checkout.");
        }
        e.preventDefault();
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
        GetProducts(res => {
            setProducts(res);
        });
        GetAdminSettings(res => {
            setShippingFee(parseFloat(res.shippingfee));
        });
        if(data.mid > 0) {
            GetDiscount({ mid: data.mid }, res => {
                setDiscount(parseFloat(res.discount));
            });
        }
        setTime(moment().format("X"));
        setCart([]);
        setShipping(0);
        setSubtotal(0);
    }, [ show, anim, data ])

    return (
        <div className={ "fixed inset-0 w-screen h-screen bg-slate-700/50 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    {
                        "Cart - " + data.name
                    }
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2">
                    <h5 className="text-sm font-bold">SELECT PRODUCTS:</h5>
                    <div className="h-80 overflow-y-auto">
                        {
                            products.map(p => (
                                <div className="border-b-2 border-black py-2 mb-2" key={ "membercart_" + p.prid + "_" + time }>
                                    <h5 className="text-green-600 font-bold">
                                        { p.name }
                                        <span className="italic text-xs float-right font-normal text-slate-700">* free shipping for every <b>{ p.free }</b> qty</span>    
                                    </h5>
                                    <div className="grid gap-2 grid-cols-2">
                                        <h5 className="font-bold text-slate-700">&#8369;{ formatMoney(p.price) }</h5>
                                        <h5>
                                            Quantity
                                            <input type="number" onChange={ (e) => { changeQty(e, p.prid) } } className="inline-block w-24 ml-2 text-center border-2 border-slate-700 rounded-md" min="0" step="1" placeholder="0" />
                                        </h5>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="text-center mt-4">
                        <h5 className="text-right text-sm pb-2 mx-4">Delivery Method: 
                            <select ref={ deliveryRef } onChange={ () => { calculate() } } className={ Themes.Input }>
                                <option value="Shipping">Shipping</option>
                                <option value="Pickup">Pickup</option>
                            </select>
                        </h5>
                        <h5 className="text-right px-4 text-sm">Subtotal: 
                            <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(subtotal) }</span>
                        </h5>
                        <h5 className="text-right px-4 text-sm">Discount: 
                            <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(subtotal * discount) } <i>({ discount * 100 }%)</i></span>
                        </h5>
                        <h5 className="text-right px-4 text-sm">Shipping Fee: 
                            <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(shipping) }</span>
                        </h5>
                        <h5 className="text-right px-4 text-sm mb-4">Total: 
                            <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(subtotal - (subtotal * discount) + shipping) }</span>
                        </h5>
                    </div>
                    <button type="submit" className={ Themes.FilledButton }>Checkout</button>
                </form>
            </animated.div>
        </div>
    )
}