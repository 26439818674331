import { useStoreState, dispatch } from "../../../common/Store";
import { Themes } from "../../../common/Constant";
import { Toggle } from "../../UI/Toggle";
import { GetWallet, GetDiscount, GetCashiers, GetAllBankAccounts, CheckoutCart, RenewCheckout, PackageCheckout, MemberMyStoreCheckout, GetAvailableStore, SendOTPOtherWallet, CheckOTPOtherWallet } from "../../../common/Request";
import { formatMoney } from "../../../common/Utils";
import { ShowAlert } from "../../UI/Alert";
import { useEffect, useState, useRef } from "react";
import xIcon from "../../../assets/img/x-mark.svg";
import check from "../../../assets/img/check-white.svg";

export const CheckoutModal = ({ data = [], type = "Repeat", paid = 0, show = false, packagePoints = 0, packageTotal = 0, onClose }) => { // type = Repeat | Package | Renew | Store

    const [ front, setFront ] = useState(false);
    const userInfo = useStoreState("userinfo");

    const deliveryRef = useRef();
    const paymentRef = useRef();
    const methodRef = useRef();
    const recNameRef = useRef();
    const recAddRef = useRef();
    const recPhoneRef = useRef();
    const refNumRef = useRef();
    const screenshotRef = useRef();
    const noteRef = useRef();
    const otpEmailRef = useRef();
    const otpRef = useRef();

    const [ points, setPoints ] = useState(0);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ discount, setDiscount ] = useState(0);
    const [ shipping, setShipping ] = useState(0);
    const [ fee, setFee ] = useState(0);
    const [ total, setTotal ] = useState(0);

    const [ deliveryMethod, setDeliveryMethod ] = useState("Shipping");
    const [ paymentMethod, setPaymentMethod ] = useState("Cash");
    const [ bankDetails, setBankDetails ] = useState();
    const [ screenshot, setScreenshot ] = useState(null);
    const [ bankList, setBankList ] = useState([]);
    const [ stores, setStores ] = useState([]);
    const [ cashiers, setCashiers ] = useState([]);
    const [ cashierId, setCashierId ] = useState(0);
    
    const [ useOtherWallet, setUseOtherWallet ] = useState(false);
    const [ otpSent, setOtpSent ] = useState(false);
    const [ otpCorrect, setOTPCorrect ] = useState(false);

    const [ wallet, setWallet ] = useState(0);
    const [ submitted, setSubmitted ] = useState(false);

    const changeDeliveryMethod = (e) => {
        setDeliveryMethod(e.target.value);
    }

    const changeModePayment = (e) => {
        methodRef.current.value = "Cash";
        setPaymentMethod("Cash");
        setCashierId(0);
        GetAllBankAccounts({ mid: e.target.value }, res => {
            setBankList(res);
        });
        if(parseInt(e.target.value) === 0) {
            for(let i = 0; i < e.target.options.length; i++) {
                if(e.target.options[i].selected) {
                    setCashierId(parseInt(e.target.options[i].dataset.cashier));
                }
            }
        }
    }

    const changePaymentMethod = (e) => {
        const v = e.target.value;
        setPaymentMethod(v);
        setScreenshot(null);
        setUseOtherWallet(false);
        setOTPCorrect(false);
        if(v !== "Cash" && v !== "Wallet Balance" && v !== "Store Wallet") {
            setBankDetails(bankList.filter(b => b.bankname === v));
        }
    }

    const onChangeScreenshot = (e) => {
        const reader = new FileReader();
        reader.onload = function(evt)  {
            setScreenshot(evt.target.result);
         }
         reader.readAsDataURL(e.target.files[0]);
    }

    const sendOTP = () => {
        SendOTPOtherWallet({
            mid: userInfo.mid,
            email: otpEmailRef.current.value
        }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "One-time code was successfully sent.");
                setOtpSent(true);
                setOTPCorrect(false);
            }else{
                ShowAlert(res.t, res.m);
            }
        })
    }

    const checkOTP = () => {
        CheckOTPOtherWallet({
            mid: userInfo.mid,
            otp: otpRef.current.value
        }, res => {
            if(res.t === "Success") {
                setOTPCorrect(true);
            }else{
                ShowAlert(res.t, res.m);
            }
        })
    }

    const response = (res) => {
        setSubmitted(false);
        if(res.t === "Success") {
            ShowAlert("Purchase successfully sent", "Please check history to track your purchase. Thank you.");
            dispatch({ type: "page", data: "memberhistory" });
        }else{
            ShowAlert(res.t, res.m);
        }
    }

    const onSubmit = (e) => {
        if(useOtherWallet && !otpCorrect) {
            ShowAlert("OTP Failed", "Please enter and check OTP to proceed.");
            e.preventDefault();
            return;
        }

        if(!useOtherWallet && paymentMethod === "Wallet Balance") {
            if(total > parseFloat(wallet.amount)) {
                ShowAlert("Can't Send Order", "You don't have enough balance from your wallet to purchase.");
                e.preventDefault();
                return;
            }
        }

        if(paymentMethod === "Store Wallet") {
            if(total > parseFloat(wallet.storewallet)) {
                ShowAlert("Can't Send Order", "You don't have enough balance from your store wallet to purchase.");
                e.preventDefault();
                return;
            }
        }

        const isShipping = deliveryMethod === "Shipping";
        const isTransfer = paymentMethod !== "Cash" && paymentMethod !== "Wallet Balance" && paymentMethod !== "Store Wallet";
        const name = isShipping ? recNameRef.current.value : "";
        const address = isShipping ? recAddRef.current.value : "";
        const phone = isShipping ? recPhoneRef.current.value : "";
        const modepayment = parseInt(paymentRef.current.value) === 0 ? "Cashier" : "Stockist";
        const storeId = parseInt(paymentRef.current.value);
        const refnum = isTransfer ? refNumRef.current.value : "";
        const photo = isTransfer ? screenshotRef.current.files[0] : "";
        const note = noteRef.current.value.replace(/"/g, "”").replace(/'/g, "’").replace(/&/g, "and");
        const otp = !useOtherWallet ? 'MyWallet' : otpRef.current.value;
        setSubmitted(true);
        if(type === "Repeat") {
            CheckoutCart({
                mid: userInfo.mid,
                delivery: deliveryMethod,
                name: name,
                address: address,
                phone: phone,
                modepayment: modepayment,
                paymentmethod: paymentMethod,
                refnum: refnum,
                screenshot: photo,
                otp: otp,
                note: note,
                store: storeId,
                cashier: cashierId,
            }, response);
        }else if(type === "Renew") {
            if(parseInt(userInfo.mrp) > points) {
                ShowAlert("Can't Send Order", "You must have " + userInfo.mrp + " points from purchase to proceed.");
                return;
            }

            RenewCheckout({ 
                mid: userInfo.mid, 
                prids: JSON.stringify(data), 
                delivery: deliveryMethod,
                name: name,
                address: address,
                phone: phone,
                modepayment: modepayment,
                paymentmethod: paymentMethod,
                refnum: refnum,
                screenshot: photo,
                otp: otp,
                note: note,
                store: storeId,
                cashier: cashierId,
            }, response)
        }else if(type === "Package") {
            PackageCheckout({
                mid: userInfo.mid, 
                paid: paid, 
                delivery: deliveryMethod,
                name: name,
                address: address,
                phone: phone,
                modepayment: modepayment,
                paymentmethod: paymentMethod,
                refnum: refnum,
                screenshot: photo,
                otp: otp,
                note: note,
                store: storeId,
                cashier: cashierId,
            }, response)
        }else if(type === "Store") {
            MemberMyStoreCheckout({
                mid: userInfo.mid, 
                storeobj: JSON.stringify(data), 
                delivery: deliveryMethod,
                name: name,
                address: address,
                phone: phone,
                modepayment: modepayment,
                paymentmethod: paymentMethod,
                refnum: refnum,
                screenshot: photo,
                otp: otp,
                note: note,
                subtotal: subtotal,
                shipping: shipping,
                fee: fee,
                total: total,
                cashier: cashierId,
            }, response)
        }
        e.preventDefault();
    }

    useEffect(() => {
        // console.log(data)
        GetWallet({ mid: userInfo.mid }, res => {
            setWallet(res);
        });

        GetCashiers(res => {
            setCashiers(res);
        })

        if(type !== "Package" && type !== "Store") {
            GetDiscount({ mid: userInfo.mid }, res => {
                setDiscount(parseFloat(res.discount));
            });
        }

        GetAllBankAccounts({ mid: paymentRef.current.value | 0 }, res => {
            setBankList(res);
        });

        GetAvailableStore({ 
            data: type === "Package" ? [{ "paid": paid, "qty": 1 }] : data 
        }, res => {
            setStores(res);
        });

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

        let sub = 0, points = 0, sf = 0, af = 0, t = 0, freeShipping = true;
        data.forEach(d => {
            sub += parseFloat(d.price) * parseInt(d.qty);
            points += parseFloat(d.points) * parseInt(d.qty);
            if((parseInt(d.qty) / parseInt(d.free)).toString().indexOf(".") > -1) {
                freeShipping = false;
            }
        });
        setSubtotal(sub);
        setPoints(type === "Package" ? packagePoints : points);
        if(deliveryMethod === "Pickup" || type === "Package") {
            setShipping(0);
        }else{
            setShipping(freeShipping ? 0 : parseFloat(userInfo.shipping));
            sf = freeShipping ? 0 : parseFloat(userInfo.shipping);
        }
        
        t = sub - (sub * discount) + sf;

        if(paymentMethod === "Wallet Balance") {
            af = type === "Package" ? parseFloat(userInfo.packagefee) : parseFloat(userInfo.otherfee) * (t - sf);
        }

        if(type === "Package") {
            setTotal(packageTotal + sf + af);
            setDiscount((sub - packageTotal) / sub);
            setShipping(0);
        }else if(type === "Store") {
            t = 0;
            setDiscount(0);
            data.forEach(d => {
                t += parseFloat(d.total);
            });
            if(paymentMethod === "Wallet Balance") {
                af = parseFloat(userInfo.otherfee) * t;
            }
            setTotal(t + sf + af);
        }else{
            setTotal(t + af);
        }
        
        
        setFee(af);
    }, [ show, data, type, packagePoints, packageTotal, paid, userInfo, discount, deliveryMethod, paymentMethod ])

    return (
        <div data-front={ front }
            className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
                <div data-show={ show } 
                    className="transition ease-linear duration-300 w-full max-w-screen-sm mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4"
                    >
                    <div className="bg-slate-700 py-2 px-4">
                        <h1 className="text-white text-xl inline">Checkout</h1>
                        <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                    </div>
                    <div className="bg-white p-4">
                        {
                            type === "Renew" ?
                                <h1 className="text-blue-600 text-xl font-bold">Account Renewal</h1>
                            : type === "Package" ?
                                <h1 className="text-blue-600 text-xl font-bold">Account Activation</h1>
                            : null
                        }
                        <b className="text-slate-700">Purchase Details</b>
                        <table className="text-xs w-full">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((d, i) => (
                                        <tr key={ "item_" + i }>
                                            <td>{ d.name }</td>
                                            <td className="text-right">{ formatMoney(d.price) }</td>
                                            <td className="text-center">{ d.qty }</td>
                                            <td className="text-right">{ formatMoney(parseInt(d.qty) * parseFloat(d.price)) }</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <form onSubmit={ onSubmit } className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                            <div>
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Delivery Method:</b>
                                    <select ref={ deliveryRef } onChange={ changeDeliveryMethod } className="bg-slate-700 text-white w-full px-2 py-1 font-bold" required>
                                        <option value="Shipping">Shipping</option>
                                        <option value="Pickup">Pickup</option>
                                    </select>
                                </div>
                                {
                                    deliveryMethod === "Shipping" ?
                                        <div className="text-sm my-2">
                                            <b className="text-slate-700">Receiver Details:</b>
                                            <input type="text" ref={ recNameRef } placeholder="Full Name" className="border-2 border-slate-700 text-black w-full px-2 py-1 mb-1" required />
                                            <input type="text" ref={ recAddRef } placeholder="Complete Address" className="border-2 border-slate-700 text-black w-full px-2 py-1 mb-1" required />
                                            <input type="text" ref={ recPhoneRef } placeholder="Contact Number" className="border-2 border-slate-700 text-black w-full px-2 py-1" required />
                                        </div>
                                    : null
                                }
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Mode of Payment:</b>
                                    <select ref={ paymentRef } onChange={ changeModePayment } className="bg-slate-700 text-white w-full px-2 py-1 font-bold" required>
                                        <option value="" hidden>Select</option>
                                        {
                                            cashiers.map((c, i) => (
                                                <option value="0" data-cashier={ c.mid } key={ "cashier_" + i }>{ c.name }</option>
                                            ))
                                        }
                                        {
                                            stores.map((s, i) => (
                                                <option value={ s.mid } data-stockist={ s.mid } key={ "stockist_" + i }>{ s.name } ({ s.address })</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Payment Method:</b>
                                    <select ref={ methodRef } onChange={ changePaymentMethod } className="bg-slate-700 text-white w-full px-2 py-1 font-bold" required>
                                        <option value="Cash">Cash</option>
                                        <option value="Wallet Balance">Wallet Balance (&#8369;{ formatMoney(wallet.amount) })</option>
                                        {
                                            type === "Store" ?
                                            <option value="Store Wallet">My Store Wallet Balance (&#8369;{ formatMoney(wallet.storewallet) })</option>
                                            : null
                                        }
                                        {
                                            bankList.map((b, i) => (
                                                <option value={ b.bankname } key={ "bank_" + i }>{ b.bankname }</option>
                                            ))
                                        }
                                    </select>
                                    {
                                        paymentMethod === "Wallet Balance" ?
                                            <div>
                                                {/* <div className="grid grid-cols-2/1 gap-2 items-center">
                                                    <h5 className="text-sm text-blue-600">Insufficient balance? Use other account's wallet.</h5>
                                                    <div className="text-right"><Toggle onChange={ (b) => { setUseOtherWallet(b); } } /></div>
                                                </div> */}
                                                <div className="mt-2">
                                                    <input ref={ otpEmailRef } type="email" className="bg-blue-200 text-blue-700 font-bold w-56 rounded-full px-2 py-1" placeholder="Account's Email" required />
                                                    <button onClick={ sendOTP } type="button" className="bg-blue-700 text-white text-xs font-bold rounded-full px-2 py-1.5 w-[72px]">Send OTP</button>
                                                </div>
                                                {
                                                    otpSent ? 
                                                    <div>
                                                        <input ref={ otpRef } type="text" className="bg-blue-200 text-blue-700 font-bold w-56 rounded-full px-2 py-1" placeholder="One-time Code" required />
                                                        {
                                                            !otpCorrect ?
                                                                <button onClick={ checkOTP } type="button" className="bg-blue-700 text-white text-xs font-bold rounded-full px-2 py-1.5 w-[72px]">Check</button>
                                                            : <button type="button" className="bg-green-700 text-white text-xs font-bold rounded-full px-2 py-1.5 w-[72px]">Correct!</button>
                                                        }
                                                        
                                                    </div>
                                                : null
                                                }
                                            </div>
                                        : null
                                    }
                                </div>
                                {
                                    paymentMethod !== "Cash" && paymentMethod !== "Wallet Balance" && paymentMethod !== "Store Wallet" ?
                                        <div className="text-sm my-2">
                                            <div className="text-xs">
                                                Please send your payment for this receiver details<br />
                                                <b className="text-blue-700">Account Name: </b><b>{ bankDetails[0].acctname }</b><br />
                                                <b className="text-blue-700">Account Number: </b><b>{ bankDetails[0].acctnum }</b>
                                            </div>
                                            <input type="text" ref={ refNumRef } placeholder="Reference Number" className="border-2 border-slate-700 text-black w-full px-2 py-1 my-1" required />
                                            <div className="text-xs">Upload screenshot as your proof of receipt</div>
                                            <input type="file" ref={ screenshotRef } accept="image/*" onChange={ onChangeScreenshot } placeholder="Reference Number" className="border-2 border-slate-700 text-black w-full px-2 py-1 my-1" required />
                                            {
                                                screenshot ? <img src={ screenshot } className="w-32 m-auto mb-4" alt="screenshot" /> : null
                                            }
                                        </div>
                                    : null
                                }
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Note:</b>
                                    <textarea type="text" ref={ noteRef } placeholder="Leave your note here" className="border-2 border-slate-700 text-black w-full px-2 py-1 mb-1 resize-none" />
                                </div>
                            </div>
                            <div className="my-4 text-right">
                                {
                                    type !== "Store" ?
                                    <h5 className="px-4 text-sm">Total Points: 
                                        <span className="text-slate-700 font-bold text-lg ml-2">{ points } pts.</span>
                                    </h5>
                                    : null
                                }
                                <h5 className="px-4 text-sm">Subtotal: 
                                    <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(subtotal) }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Discount: 
                                    <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ 
                                        type === "Store" ? formatMoney(subtotal - (total - (shipping + fee))) : formatMoney(subtotal * discount) 
                                    } { type !== "Store" ? <i>({ discount * 100 }%)</i> : null }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Shipping Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(shipping) }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Admin Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(fee) }</span>
                                </h5>
                                <h5 className="px-4 text-sm mb-4">Total: 
                                    <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(total) }</span>
                                </h5>
                                {
                                    !submitted ?
                                    <button type="submit" 
                                        className={ "inline transition duration-300 text-sm text-center h-10 text-white px-4 py-2 mt-4 bg-amber-500 border-2 border-amber-500 rounded-full font-medium hover:bg-slate-700 hover:border-slate-700" } 
                                        >
                                        <img src={ check } className={ Themes.HeaderButtonImage} alt="checkicon" />
                                        Checkout ( &#8369;{ formatMoney(total) } )
                                    </button>
                                    : null
                                }
                            </div>
                        </form>
                    </div>
                </div>
        </div>
    )
}