import { animated, useSpring } from "@react-spring/web";
import { getTypeColor } from "../../common/Utils";
import { dispatch, useStoreState } from "../../common/Store";
import logo from "../../assets/img/logo.png";

export const Navigation = () => {

    const page = useStoreState("page");
    const userInfo = useStoreState("userinfo");
    // const links = [["Dashboard", "memberdashboard"], ["Logout", "login"]];
    const links = [
        ["Dashboard", "memberdashboard"], 
        ["Products", "memberproducts"], 
        ["Geonology", "memberbinary"], 
        ["Wallet", "memberwallet"], 
        ["History", "memberhistory"], 
        ["Logout", "login"]
    ];

    const [ props, animation ] = useSpring(
        () => ({ 
            right: "-100%",
            opacity: 0,
            config: {
                duration: 100
            }
        }), 
    []);

    const menu = (show = true) => {
        animation.start({ 
            to: [ { right: show ? "0px" : "-100%", opacity: show ? 1 : 0 } ] 
        });
    }

    const clickSideMenu = (p) => {
        dispatch({ type: "page", data: p });
        menu(false);
    }

    return (
        <nav className="relative w-screen bg-white px-2 py-1 drop-shadow-xl flex justify-between z-10">
            <img src={ logo } className="w-8 inline-block" alt="logo" />

            <button type="button" className="mx-4 block md:hidden" onClick={ () => { menu() } }>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 9h16.5m-16.5 6.75h16.5" />
                </svg>
            </button>

            <animated.div style={props} className="float-right text-right z-50 absolute right-0 top-0 opacity-0 bg-gradient-to-b from-gray-50 h-screen 
                md:static md:text-left md:!opacity-100 md:h-auto md:bg-none">
                <button type="button" className="mx-4 my-1 inline-block md:hidden" onClick={ () => { menu(false) } }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </button>
                {
                    links.map(l => (
                        <button className={ 
                                "mx-4 block md:inline-block my-2 px-2 transition-all ease-linear duration-300 md:my-0" +
                                (page === l[1] ? " text-white " + getTypeColor(userInfo).bg + " pb-2.5 md:-mb-3" : " text-slate-700 hover:" + getTypeColor(userInfo).text)
                            } 
                            type="button"
                            onClick={ () => { clickSideMenu(l[1]) } } 
                            key={ l[1] }>
                                { l[0] }
                        </button>
                    ))
                }
            </animated.div>
        </nav>
    );

}