import moment from "moment";
import { getTypeColor } from "../../common/Utils";
import { useStoreState, dispatch } from "../../common/Store";
import { Themes } from "../../common/Constant";
import { formatMoney } from "../../common/Utils";
import { GetCart, UpdateCart, GetDiscount } from "../../common/Request";
import cart from "../../assets/img/shopping-cart.svg";
import check from "../../assets/img/check-white.svg";
import { CheckoutModal } from "./component/CheckoutModal";
import { useEffect, useState } from "react";

export const MemberCart = () => {

    const userInfo = useStoreState("userinfo");
    const update = useStoreState("updatecart");
    const [ show, setShow ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ badge, setBadge ] = useState(0);
    const [ showCheckout, setShowCheckout ] = useState(false);
    const [ discount, setDiscount ] = useState(0);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ shipping, setShipping ] = useState(0);
    const [ totalPoints, setTotalPoints ] = useState(0);

    const toggle = () => {
        setShow(show ? false : true);
    }

    const changeQty = (e, prid) => {
        let obj = data;
        for(let i = obj.length - 1; i > -1; i--) {
            if(parseInt(prid) === parseInt(obj[i].prid)) {
                obj[i].qty = parseInt(e.target.value);
                if(parseInt(e.target.value) === 0) {
                    obj.splice(i, 1);
                }
            }
        }
        setData(obj);
        setBadge(obj.length);
        UpdateCart({ mid: userInfo.mid, prid: prid, qty: parseInt(e.target.value) }, res => {
            dispatch({ type: "updatecart", data: moment().format("x") });
        });
    }

    useEffect(() => {
        let dat = [];
        function render() {
            let sub = 0, points = 0, freeShipping = true;
            dat.forEach(d => {
                sub += parseFloat(d.price) * parseInt(d.qty);
                points += parseFloat(d.points) * parseInt(d.qty);
                if((parseInt(d.qty) / parseInt(d.free)).toString().indexOf(".") > -1) {
                    freeShipping = false;
                }
            });
            setSubtotal(sub);
            setTotalPoints(points);
            setShipping(freeShipping ? 0 : parseFloat(userInfo.shipping));
        }

        GetCart({ mid: userInfo.mid }, res => {
            if(res) {
                const obj = JSON.parse(res.obj);
                dat = obj;
                setData(obj);
                setBadge(obj.length);
                render();
            }
        });
        
        GetDiscount({ mid: userInfo.mid }, res => {
            setDiscount(parseFloat(res.discount));
            render();
        });
    }, [ update, userInfo ]);

    return (
        <div>
            <div className={ "text-slate-700 transition duration-300 fixed right-0 bottom-0 z-10 " + (show ? "translate-x-0" : "translate-x-full") }>
                <button type="button" className={ "absolute right-full bottom-0 p-4 w-16 rounded-s-full m-0 " + getTypeColor(userInfo).bg } onClick={ () => { toggle() } }>
                    <img src={ cart } className="w-16" alt="carticon" />
                    {
                        badge > 0 ? <div className="absolute top-1 right-1 w-6 h-6 bg-red-800 text-white rounded-full">{ badge }</div> : null
                    }
                </button>

                <div className={ "w-80 min-h-96 block pb-2 drop-shadow-lg bg-slate-200" }>
                    <h3 className={ "text-center text-lg font-bold text-white py-2 " + getTypeColor(userInfo).bg }>My Cart</h3>
                    <div className="h-80 overflow-y-auto px-4">
                    {
                        badge > 0 ? 
                            data.map(d => (
                                <div className="py-2 border-b-2 border-slate-700" key={ "cart_" + d.prid + "_" + moment().format("x") }>
                                    <h5 className="font-bold">{ d.name }</h5>
                                    <h5 className="text-sm">
                                        &#8369;{ formatMoney(d.price) }
                                        <div className="float-right">
                                            Qty: <input type="number" onChange={ (e) => { changeQty(e, d.prid) } } className="w-20 text-center rounded-sm font-bold" defaultValue={ d.qty } step="1" min="0" />
                                        </div>
                                    </h5>
                                    <h5 className="text-black">
                                        <span className="italic text-xs">* free shipping for every <b>{ d.free }</b> qty</span>
                                        <b className="float-right text-amber-600">&#8369;{ formatMoney(parseFloat(d.price) * d.qty) }</b>
                                    </h5>
                                </div>
                            ))
                        : <h5 className="text-center m-4">No products to checkout</h5>
                    }
                    </div>
                    {
                        badge > 0 ? 
                            <div>
                                <h5 className="text-right px-4 text-sm">Total Points: 
                                    <span className="text-slate-700 font-bold text-lg ml-2">{ totalPoints } pts.</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Subtotal: 
                                    <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(subtotal) }</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Discount: 
                                    <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(subtotal * discount) } <i>({ discount * 100 }%)</i></span>
                                </h5>
                                <h5 className="text-right px-4 text-sm">Shipping Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(shipping) }</span>
                                </h5>
                                <h5 className="text-right px-4 text-sm mb-4">Total: 
                                    <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(subtotal - (subtotal * discount) + shipping) }</span>
                                </h5>
                                <button type="button" className={ Themes.HeaderButton + " block mx-auto" } onClick={ () => { setShowCheckout(true); toggle(); } }>
                                    <img src={ check } className={ Themes.HeaderButtonImage } alt="checkicon" />
                                    Checkout
                                </button>
                            </div>
                        : null
                    }
                </div>
                
            </div>
            <CheckoutModal data={ data } type="Repeat" show={ showCheckout } onClose={ () => { setShowCheckout(false) } } />
        </div>
    )
}