import { dispatch, useStoreState } from "../../common/Store";
import { Site, Themes } from "../../common/Constant";
import { GetWallet, GetPurchases, GetDiscount } from "../../common/Request";
import { formatMoney, getTypeColor } from "../../common/Utils";
import { Navigation } from "./Navigation";
import { MemberCart } from "./MemberCart";
// import { SettingsModal } from "./component/SettingsModal";
import { MyStoreModal } from "./component/MyStoreModal";
import user from "../../assets/img/user-white.svg";
import copy from "../../assets/img/copy.svg";
import locationIcon from "../../assets/img/location.svg";
import emailIcon from "../../assets/img/email.svg";
import phoneIcon from "../../assets/img/phone.svg";
import storeIcon from "../../assets/img/store-white.svg";
// import settingsIcon from "../../assets/img/settings-white.svg";
import { useEffect, useRef, useState } from "react";

export const MemberDashboard = () => {

    const userInfo = useStoreState("userinfo");
    const [ wallet, setWallet ] = useState({ amount: 0, earns: 0, peb: 0, pppb: 0, psb: 0, rb: 0, cb: 0, gtb: 0, glb: 0, rpb: 0, safety: 2500 });
    const [ hasPackage, setHasPackage ] = useState(true);
    const [ hasRenew, setHasRenew ] = useState(false);
    const [ rank, setRank ] = useState("");
    // const [ showSettings, setShowSettings ] = useState(false);
    const [ showStore, setShowStore ] = useState(false);
    const shareRef = useRef();

    const wholesale = () => {
        dispatch({ type: "page", data: "packagepurchase" });
    }

    const renew = () => {
        dispatch({ type: "page", data: "renewpurchase" });
    }

    const claimRewards = () => {
        dispatch({ type: "page", data: "claimrewards" });
    }

    const share = () => {
        navigator.clipboard.writeText(shareRef.current.value).then(() => {
            alert("Copied to clipboard!");
        })
    }

    useEffect(() => {
        GetWallet({ mid: userInfo.mid }, res => {
            setWallet(res);
        });

        GetPurchases({ mid: userInfo.mid }, res => {
            const filter = res.filter(r => parseInt(r.paid) > 0 && r.status !== 'DECLINED');
            setHasPackage(filter.length > 0 || userInfo.type !== "Reseller");
            res.forEach(p => {
                if(parseInt(p.paid) === 0 && parseInt(p.renew) > 0 && p.status === "PENDING") {
                    setHasRenew(true);
                }
            });
        });
        
        GetDiscount({ mid: userInfo.mid }, res => {
            setRank(res.rank);
        })
    }, [ userInfo, showStore ]);

    return (
        <div>
            <Navigation />
            <MemberCart />
            {/* <SettingsModal show={ showSettings } onClose={ () => { setShowSettings(false) } } /> */}
            {
                userInfo.store ?
                <MyStoreModal show={ showStore } storeWallet={ wallet.storewallet | 0 } onClose={ () => { setShowStore(false) } } />
                : null
            }
            <div className="grid gap-4 p-4 grid-cols-1 md:grid-cols-1/2">
                <div className="p-4 bg-white w-full text-center relative">
                    {/* <button type="button" onClick={ () => { setShowSettings(true) } } className={ "bg-slate-700 absolute -right-2 -top-2 p-3 rounded-full hover:animate-spin" }>
                        <img src={ settingsIcon } className="w-8" alt="settingsicon" />
                    </button> */}
                    <img src={ user } className={ "w-24 h-24 object-fit mb-4 p-1 block m-auto " + getTypeColor(userInfo).bg + " rounded-full" } alt="usericon" />
                    <h1 className="text-xl font-bold text-slate-700">{ userInfo.name }</h1>
                    <h1 className={ "text-lg font-bold " + getTypeColor(userInfo).text }>[ { rank !== "" ? rank : userInfo.type } ]</h1>
                    <h1 className="text-sm font-bold text-slate-700">Code: { userInfo.code }</h1>
                    <h3 className={ Themes.Italic }>
                        <img src={ emailIcon } className="w-4 inline-block mr-1" alt="emailicon" />{ userInfo.email }
                    </h3>
                    <h3 className={ Themes.Italic }>
                        <img src={ phoneIcon } className="w-4 inline-block mr-1" alt="phoneicon" />{ userInfo.phone }
                    </h3>
                    <h3 className={ Themes.Italic }>
                        <img src={ locationIcon } className="w-4 inline-block mr-1" alt="locationicon" />{ userInfo.address }
                    </h3>
                    {
                        !hasPackage ?
                        <button type="button" className={ Themes.FilledButton } onClick={ () => { wholesale(); } }>Activate Wholesaler</button> : null
                    }
                    {
                        parseFloat(wallet.safety) <= 0 && !hasRenew ?
                        <button type="button" className={ Themes.FilledButton + " animate-pulse"} onClick={ () => { renew(); } }>Click for Renew</button> : null
                    }
                    <hr />
                    <div className="relative mt-2">
                        <img src={ copy } className={ Themes.InputButtonIcon } onClick={ () => { share(); } } alt="copyicon" />
                        <input type="text" ref={ shareRef } className={ Themes.InputField } value={ Site + "?ref=" + userInfo.code } readOnly />
                    </div>
                    {
                        userInfo.store ?
                        <div className="my-4">
                            <button type="button" onClick={ () => { setShowStore(true) } } className="bg-gradient-to-r from-teal-400 to-teal-600 p-3 rounded-full border-4 border-white shadow-md">
                                <img src={ storeIcon } className="w-12 h-12" alt="storeicon" />
                            </button>
                            <h5 className="text-slate-700 font-bold">My Store</h5>
                        </div>
                        : null
                    }
                    
                </div>
                <div className="w-full ">
                    <div className="grid gap-4 grid-cols-2 mb-4">
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadAmber }>Wallet Balance</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.amount | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadAmber }>Total Earnings</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.earns | 0) }</h1>
                        </div>
                    </div>
                    <div className="grid gap-4 grid-cols-2 md:grid-cols-3">
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Personal Endorsement Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.peb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Personal Product Purchased Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.pppb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Personal Sponsor Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.psb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Reward Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.rb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Consumers Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.cb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Group Consumer Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.gcb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Group Team Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.gtb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen }>Group Leadership Bonus</h3>
                            <h1 className={ Themes.DashboardAmount }>{ formatMoney(wallet.glb | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen + " from-purple-600 to-purple-700" }>Earnings Threshold</h3>
                            <h1 className={ Themes.DashboardAmount + " text-purple-700" }>{ formatMoney(wallet.safety | 0) }</h1>
                        </div>
                        <div className="bg-white text-center h-min">
                            <h3 className={ Themes.PanelHeadGreen + " from-rose-600 to-rose-700" }>Reward Points Bonus</h3>
                            <h1 className={ Themes.DashboardAmount + " text-rose-700" }>
                                { wallet.rpb | 0 }
                                <button type="button" onClick={ () => { claimRewards(); } } className={ Themes.DashboardButton }>Redeem</button>    
                            </h1>
                        </div>
                        {
                            userInfo.store ?
                            <div className="bg-white text-center h-min">
                                <h3 className={ Themes.PanelHeadGreen + " from-teal-400 to-teal-600" }>Store Wallet</h3>
                                <h1 className={ Themes.DashboardAmount + " text-teal-600" }>
                                    { formatMoney(wallet.storewallet | 0) }
                                    <button type="button" onClick={ () => { claimRewards(); } } className={ Themes.DashboardButton + " bg-teal-600" }>Payout</button>    
                                </h1>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}