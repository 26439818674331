import moment from "moment";
import DataTable from "react-data-table-component";
import { Themes } from "../../common/Constant";
import { GetAdminPayouts } from "../../common/Request";
import { formatMoney } from "../../common/Utils";
import { Navigation } from "./Navigation";
import { PayoutModal } from "./component/PayoutModal";
import { useEffect, useState, useMemo } from "react";
import searchIcon from "../../assets/img/search.svg";
import editIcon from "../../assets/img/edit.svg";

export const AdminPayoutRequests = () => {

    const [ showDetails, setShowDetails ] = useState(false);
    const [ detailsData, setDetailsData ] = useState(null);

    const details = (data) => {
        setShowDetails(true);
        setDetailsData(data);
    }

    const reloadData = () => {
        GetAdminPayouts(res => {
            setData(res);
        });
    }

    const [ data, setData ] = useState([]);
    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => <span>&#8369; { formatMoney(row.amount) }</span>,
            sortable: true,
        },
        {
            name: 'Admin Fee',
            selector: row => <span className="text-red-600">-&#8369; { formatMoney(row.fee) }</span>,
            sortable: true,
        },
        {
            name: 'Total Payout',
            selector: row => <span className="text-green-600">&#8369; { formatMoney(parseFloat(row.amount) - parseFloat(row.fee)) }</span>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "COMPLETED" ? "text-green-600" : row.status === "DECLINED" ? "text-red-600" : "text-amber-500" }>{ row.status }</b>,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <img src={ editIcon } onClick={ () => { details(row); } } className={ Themes.ImageButton } alt="editicon" />,
        },
        {
            name: 'Created',
            selector: row => moment(row.created).format("MMM DD, YYYY"),
            sortable: true,
        },
    ];

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

    const subHeaderComponentMemo = useMemo(() => {
		return (
            <div className="relative">
                <img src={ searchIcon } className={ Themes.InputIcon } alt="searchicon" />
                <input type="text" className={ Themes.InputField } placeholder="Filter By Name" value={ filterText } onChange={ (e) => { setFilterText(e.target.value) } } />
            </div>
		);
	}, [filterText]);

    useEffect(() => {
        GetAdminPayouts(res => {
            setData(res);
        });
    }, [])

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen p-8">
                <DataTable
                    title="Payout Requests"
                    pagination
                    columns={ columns }
                    data={ filteredItems }
                    subHeader
                    subHeaderComponent={ subHeaderComponentMemo }
                />
            </div>
            <PayoutModal data={ detailsData } show={ showDetails } onClose={ () => { setShowDetails(false) } } onChange={ reloadData } />
        </div>
    )
}