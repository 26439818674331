import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../../../common/Constant";
import { GetWallet, SendPayoutOTP, PayoutRequest } from "../../../common/Request";
import { formatMoney } from "../../../common/Utils";
import { ShowAlert } from "../../UI/Alert";
import { useRef, useState } from "react";
import closeIcon from "../../../assets/img/white-x.svg";
import { useEffect } from "react";
import { useStoreState } from "../../../common/Store";

export const PayoutForm = ({ show = false, hide }) => {

    const userInfo = useStoreState("userinfo");
    const [ wallet, setWallet ] = useState(null);
    const [ fee, setFee ] = useState(0);
    const [ method, setMethod ] = useState(null);
    const [ otpSent, setOtpSent ] = useState(false);
    const acctNameRef = useRef();
    const acctNumRef = useRef();
    const otpRef = useRef();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const sendOTP = (e) => {
        SendPayoutOTP({ mid: userInfo.mid }, res => {
            if(res.t === "Success") {
                ShowAlert("Validate OTP", "Your One-time code has been sent to your email. Please enter the OTP you received to validate.");
                setOtpSent(true);
            }else{
                ShowAlert(res.t, res.m);
            }
        })
    }

    const onSubmit = (e) => {
        const c = window.confirm("Request for payout?");
        if(c) {
            PayoutRequest({ 
                mid: userInfo.mid,
                method: method,
                acctname: method !== "Over-The-Counter" ? acctNameRef.current.value : "",
                acctnum: method !== "Over-The-Counter" ? acctNumRef.current.value : "",
                otp: otpRef.current.value
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "Request payout sent!, please proceed to cashier to encash.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }
        e.preventDefault();
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }

        GetWallet({ mid: userInfo.mid }, res=> {
            setWallet(res);
            setFee(parseFloat(userInfo.fee) * parseFloat(res.payout.amount));
            if(res.payout.amount < 100 && show) {
                setTimeout(() => { ShowAlert("Payout Failed", "You don't have enough balance from your wallet to payout.") }, 500);
            }
        });
    }, [ show, anim, userInfo ])

    return (
        <div className={ "fixed z-40 inset-0 w-screen h-screen bg-slate-700/50 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    Request for Payout
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2">
                    <select onChange={ (e) => { setMethod(e.target.value) } } className="w-full text-lg bg-slate-200 text-slate-700 p-2 rounded-md mt-2" required>
                        <option value="" hidden>Sending Method</option>
                        <option value="Over-The-Counter">Over-The-Counter</option>
                        <option value="BDO">BDO</option>
                        <option value="BPI">BPI</option>
                        <option value="GCash">GCash</option>
                        <option value="Metrobank">Metrobank</option>
                        <option value="Security Bank">Security Bank</option>
                    </select>
                    {
                        method !== "Over-The-Counter" ?
                            <div>
                                <input ref={ acctNameRef } type="text" placeholder={ (method === "Palawan Express" ? "Full" : "Account") + " Name:" } className="w-full text-lg bg-amber-100 text-black p-2 rounded-md mt-2" required />
                                <input ref={ acctNumRef } type="text" placeholder={ (method === "GCash" || method === "Palawan Express" ? "Phone" : "Account") + " Number:" } className="w-full text-lg bg-amber-100 text-black p-2 rounded-md mt-2" required />
                            </div>
                        : null
                    }
                    <div className="grid grid-cols-2 gap-2 mt-4">
                        <div>
                            <h5 className="text-sm text-slate-700">Total Balance</h5>
                            <h1 className="text-2xl font-bold text-slate-700 ml-4 mb-2">&#8369; { formatMoney(wallet ? wallet.payout.amount : 0) }</h1>
                        </div>
                        <div>
                            <h5 className="text-sm text-slate-700">Admin Fee ({ parseFloat(userInfo.fee) * 100 }%)</h5>
                            <h1 className="text-2xl font-bold text-red-600 ml-4 mb-2">-&#8369; { formatMoney(fee) }</h1>
                        </div>
                    </div>
                    <h5 className="text-sm text-slate-700">Total Payout Amount</h5>
                    <h1 className="text-4xl font-bold text-green-600 ml-4 mb-4">&#8369; { formatMoney(wallet ? parseFloat(wallet.payout.amount) - fee : 0) }</h1>
                    {
                        wallet ?
                            wallet.payout.amount > 0 ?
                            <div className="mt-8">
                                {
                                    otpSent ?
                                    <h5 className="text-blue-700 text-sm font-bold">Enter the 6-digit verification code that was sent to your email address.</h5>
                                    : null
                                }
                                <div className="grid grid-cols-2/1 gap-4 mb-4">
                                    <div>
                                        <input ref={ otpRef } type="text" placeholder="One-time code" className="w-full text-center text-lg bg-amber-100 text-black p-2 rounded-md" required/>
                                    </div>
                                    <button type="button" onClick={ sendOTP } className="bg-blue-700 text-white w-full rounded-full">Send OTP</button>
                                </div>
                                <button type="submit" className={ Themes.FilledButton }>Request</button>
                            </div>
                            : null
                        : null
                    }
                </form>
            </animated.div>
        </div>
    )

}