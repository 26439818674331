import { useEffect, useState } from "react";
import { formatMoney } from "../../common/Utils";
import { GetAdminMonitoring } from "../../common/Request";
import { Navigation } from "./Navigation";
import { Panel } from "../UI/Panel";
import { LabelBox } from "../UI/LabelBox";

export const AdminDashboard = () => {

    const [ data, setData ] = useState({
        Member: 0,
        PackagePurchase: 0,
        Products: 0,
        Renew: 0,
        RepeatPurchase: 0,
        Stores: 0,
        TotalSales: 0,
        TotalPayout: 0,
        TotalNet: 0,
        Cashiers: [],
    });

    useEffect(() => {
        GetAdminMonitoring(res => {
            setData(res);
        })
    }, []);

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen p-8 overflow-y-auto">
                <div className="flex flex-row w-full mb-8">
                    <Panel className="basis-1/3 mr-2" borderColor={ "border-green-600" } bgColor={ "bg-green-600" } title="Sales"
                        body={ 
                            <div>
                                <label className="text-sm">Package Purchases</label>
                                <h5 className="text-xl font-bold">{ formatMoney(data.PackageSales) }</h5>
                                <label className="text-sm">Repeat Purchases</label>
                                <h5 className="text-xl font-bold">{ formatMoney(data.RepeatSales) }</h5>
                            </div>
                        }
                        footer={
                            <h5 className="text-2xl font-bold text-white">{ formatMoney(data.TotalSales) }</h5>
                        }
                    />
                    <Panel className="basis-1/3 mx-2" borderColor={ "border-red-500" } bgColor={ "bg-red-500" } title="Payouts"
                        body={ 
                            <div>
                                <label className="text-sm">Hold</label>
                                <h5 className="text-xl font-bold">{ formatMoney(data.HoldPayout) }</h5>
                                <label className="text-sm">Pending</label>
                                <h5 className="text-xl font-bold">{ formatMoney(data.PendingPayout) }</h5>
                                <label className="text-sm">Completed</label>
                                <h5 className="text-xl font-bold">{ formatMoney(data.CompletedPayout) }</h5>
                            </div>
                        } 
                        footer={
                            <h5 className="text-2xl font-bold text-white">{ formatMoney(data.TotalPayout)  }</h5>
                        }
                    />
                    <Panel className="basis-1/3 ml-2" borderColor={ "border-amber-500" } bgColor={ "bg-amber-500" } title="Total Net" 
                        body={ 
                            <div>
                                <label className="text-sm">Sales</label>
                                <h5 className="text-xl font-bold text-green-400">&#43;{ formatMoney(data.TotalSales) }</h5>
                                <label className="text-sm">Payouts</label>
                                <h5 className="text-xl font-bold text-red-400">&minus;{ formatMoney(data.TotalPayout) }</h5>
                            </div>
                        } 
                        footer={
                            <h5 className="text-2xl font-bold text-white">{ formatMoney(data.TotalNet)  }</h5>
                        }
                    />
                </div>
                <div className="grid gap-4 grid-cols-3 w-full">
                    <LabelBox title="Members" body={ formatMoney(data.Member, 0) } />
                    <LabelBox title="Package Purchase" body={ formatMoney(data.PackagePurchase, 0) } />
                    <LabelBox title="Repeat Purchase" body={ formatMoney(data.RepeatPurchase, 0) } />
                    <LabelBox title="Total Renew" body={ formatMoney(data.Renew, 0) } />
                    <LabelBox title="Store" body={ formatMoney(data.Stores, 0) } />
                    <LabelBox title="Products" body={ formatMoney(data.Products, 0) } />
                    <LabelBox title="Reward Products" body={ formatMoney(data.RewardProducts, 0) } />
                </div>
                <div className="text-white my-8">
                    <h1 className="text-2xl">List of Cashiers</h1>
                    <h5 className="text-md">Daily Transactions</h5>
                    <table className="w-full text-black text-xs">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Package</th>
                                <th>Repeat</th>
                                <th>Store (Stockist)</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.Cashiers.map(c => (
                                    <tr key={ c.name + "_cashier_list" }>
                                        <th>{ c.name }</th>
                                        <th>{ c.package } (&#8369; { formatMoney(c.totalpackage) })</th>
                                        <th>{ c.repeat } (&#8369; { formatMoney(c.totalrepeat) })</th>
                                        <th>{ c.store } (&#8369; { formatMoney(c.totalstore) })</th>
                                        <th>{ c.trans } (&#8369; { formatMoney(c.sales) })</th>
                                    </tr> 
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    )
}