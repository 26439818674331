import moment from "moment";
import { useStoreState } from "../../../common/Store";
import { useEffect, useMemo, useState } from "react";
import { Themes } from "../../../common/Constant";
import DataTable from "react-data-table-component";
import { PayoutForm } from "../form/PayoutForm";
import { PayoutModal } from "../component/PayoutModal";
import { formatMoney } from "../../../common/Utils";
import { GetPayouts } from "../../../common/Request";
import moneyIcon from "../../../assets/img/money.svg";

export const WalletTable = () => {

    const userInfo = useStoreState("userinfo");
    const day = moment().format("ddd");
    const hour = parseInt(moment().format("k"));

    const [ show, setShow ] = useState(false);
    const [ dataDetails, setDataDetails ] = useState(null);
    const [ showDetails, setShowDetails ] = useState(false);

    const showPayout = () => {
        setShow(true);
    }

    const hidePayout = (reload = false) => {
        setShow(false);
        if(reload) {
            GetPayouts({ mid: userInfo.mid }, res => {
                setData(res);
            });
        }
    }

    const showPayoutDetails = (details) => {
        setDataDetails(details);
        setShowDetails(true);
    }

    const [ data, setData ] = useState([]);

    const columns = [
        {
            name: 'Created',
            selector: row => moment(row.created).format("MMM DD, YYYY h:mma"),
        },
        {
            name: 'Request Amount',
            selector: row => <span>&#8369; { formatMoney(row.amount) }</span>,
            sortable: true,
        },
        {
            name: 'Admin Fee',
            selector: row => <span className="text-red-600">-&#8369; { formatMoney(row.fee) }</span>,
        },
        {
            name: 'Payout Amount',
            selector: row => <b className="text-green-600">&#8369; { formatMoney(row.amount - row.fee) }</b>,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "COMPLETED" ? "text-green-600" : row.status === "DECLINED" ? "text-red-600" : "text-amber-500" }>{ row.status }</b>,
        },
        {
            name: 'Action',
            selector: row => <button onClick={ () => { showPayoutDetails(row) } } className="transition duration-300 bg-green-500 text-white px-2 py-1 rounded-sm hover:bg-slate-700">Show Details</button>,
        },
    ];

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block">Transactions</h1>
                {
                    (day === "Tue" || day === "Wed" || day === "Thu") && hour >= 13 && hour < 18 ?
                        <button type="button" className={ Themes.HeaderButton } onClick={ () => { showPayout(); } }>
                            <img src={ moneyIcon } className={ Themes.HeaderButtonImage } alt="moneyicon" />
                            Request
                        </button>
                    : null
                }
                
            </div>
        )
    }, [ day, hour ]);

    useEffect(() => {
        GetPayouts({ mid: userInfo.mid }, res => {
            setData(res);
        });
    }, [ userInfo ])

    return (
        <div className="w-full mb-4">
            <DataTable
                title={ titleComponent }
                pagination
                columns={ columns }
                data={ data }
            />
            <PayoutForm show={ show } hide={ hidePayout } />
            <PayoutModal data={ dataDetails } show={ showDetails } onClose={ () => { setShowDetails(false) } } />
        </div>
        
    )
}