import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatMoney } from "../../../common/Utils";
import { ShowAlert } from "../../UI/Alert";
import DataTable from "react-data-table-component";
import { Themes } from "../../../common/Constant";
import { AdminGetRewardProducts, AdminDeleteRewardItem, GetPartners, AdminAddPartner, AdminDeletePartner, AdminGetRedeemRequests, AdminApproveRedemption } from "../../../common/Request";
import { RewardForm } from "../form/RewardForm";
import xIcon from "../../../assets/img/x-mark.svg";
import checkIcon from "../../../assets/img/check.svg";
import editIcon from "../../../assets/img/edit.svg";
import { useStoreState } from "../../../common/Store";

export const RewardItemsTable = () => {

    const userInfo = useStoreState("userinfo");
    const [ details, setDetails ] = useState({});
    const [ showDetails, setShowDetails ] = useState(false);
    const [ adding, setAdding ] = useState(false);
    const [ partners, setPartners ] = useState([]);
    const [ requests, setRequests ] = useState([]);
    const [ data, setData ] = useState([]);
    const partnerRef = useRef();
    const partnerPhotoRef = useRef();

    const addItem = () => {
        setDetails({name: null, description: null, price: null, points: null, photo: null});
        setShowDetails(true);
        setAdding(true);
    }

    const deleteItem = (rdid) => {
        const confirm = window.confirm("Are you sure to delete this item?");
        if(confirm) {
            AdminDeleteRewardItem({ rdid: rdid }, res => {
                AdminGetRewardProducts(r => {
                    setData(r);
                })
            });
        }
        
    }

    const editItem = (item) => {
        setDetails(item);
        setShowDetails(true);
        setAdding(false);
    }

    const hideItemDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            AdminGetRewardProducts(res => {
                setData(res)
            })
        }
    }

    let columns = [
        {
            name: 'Merchant Partner',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Points',
            selector: row => row.points,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => formatMoney(row.price),
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ row.status === "ACTIVE" ? "text-green-600" : "text-red-600" }>{ row.status }</b>,
        },
        {
            name: 'Action',
            selector: row => <div>
                                <img src={ editIcon } onClick={ () => { editItem(row); } } className={ Themes.ImageButton } alt="editicon" />
                                <img src={ xIcon } onClick={ () => { deleteItem(row.rdid); } } className={ Themes.ImageButton } alt="xicon" />
                            </div>
        },
        {
            name: 'Updated',
            selector: row => moment(row.updated).format("MMM DD, YYYY"),
        },
    ];

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block text-xl text-slate-700">Reward Products</h1>
                <button type="button" className={ Themes.HeaderButton } onClick={ () => { addItem() } }>+ New Item</button>
            </div>
        )
    }, []);

    const addPartner = (e) => {
        AdminAddPartner({ partner: partnerRef.current.value, photo: partnerPhotoRef.current.files[0] }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "New partner was added.");
                GetPartners(res => {
                    setPartners(res);
                })
            }else{
                ShowAlert("Failed", "Error found when adding new partner.");
            }
        })
        e.preventDefault();
    }

    const removePartner = (ptid) => {
        AdminDeletePartner({ ptid: ptid }, res => {
            if(res.t === "Success") {
                GetPartners(res => {
                    setPartners(res);
                })
            }else{
                ShowAlert("Failed", "Error found when adding new partner.");
            }
        })
    }

    const approveRedeem = (rmid) => {
        const confirm = window.confirm("Are you sure to approve this redemption? It means the member has already claimed the item.");
        if(confirm) {
            AdminApproveRedemption({ rmid: rmid }, res => {
                AdminGetRedeemRequests(res => {
                    setRequests(res);
                })
            })
        }
    }

    useEffect(() => {
        AdminGetRewardProducts(res => {
            setData(res)
        });

        GetPartners(res => {
            setPartners(res);
        });

        AdminGetRedeemRequests(res => {
            setRequests(res);
        })
    }, [])

    return (
        <div className="w-full mb-4">
            {
                userInfo.type === "Admin" ?
                    <div>
                        <div className="p-4 bg-white">
                            <h1 className="text-xl text-slate-700">Merchant Partners</h1>
                            <form onSubmit={ (e) => { addPartner(e); } } className="flex">
                                <input ref={ partnerRef } type="text" placeholder="Merchant's Name" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                <input ref={ partnerPhotoRef } type="file" placeholder="Merchant's Name" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                                <button type="submit" className={ Themes.HeaderButton }>+ Add</button>
                            </form>
                            <div>
                                {
                                    partners.map(p => (
                                        <div className="bg-slate-100 inline-block px-3 py-0.5 m-1 rounded-full text-slate-700" key={ p.ptid }>
                                            <img src={ p.photo } className="w-8 h-8 inline-block rounded-full" alt="partner-photo2" /> { p.partner } <img src={ xIcon } alt="removeicon" className="h-5 inline ml-2 cursor-pointer" onClick={ () => { removePartner(p.ptid); } } />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        
                        <DataTable
                            title={ titleComponent }
                            pagination
                            columns={ columns }
                            data={ data }
                        />
                    </div>
                : null
            }

            <DataTable
                title={ <div>
                            <h1 className="inline-block text-xl text-slate-700">Member's Redemptions</h1>
                        </div> 
                    }
                pagination
                columns={ [
                    {
                        name: 'Name',
                        selector: row => row.name,
                        sortable: true,
                    },
                    {
                        name: 'Item',
                        selector: row => <div>
                            {
                                row.products.map(p => (
                                    <div>{ p.qty }&times; { p.name }</div>
                                ))
                            }
                        </div>,
                        sortable: true,
                        width: "200px"
                    },
                    {
                        name: 'Total',
                        selector: row => row.type === "Reward Points" ? <div>{ row.points } pts</div> : <div>&#8369; { formatMoney(row.total) }</div>,
                        sortable: true,
                    },
                    {
                        name: 'Method',
                        selector: row => row.type,
                        sortable: true,
                    },
                    {
                        name: 'Status',
                        selector: row => <b className={ row.status === "CLAIMED" ? "text-green-600" : "text-amber-600" }>{ row.status }</b>,
                    },
                    {
                        name: 'Action',
                        selector: row =>
                            row.status === "PENDING" ?
                                <img src={ checkIcon } onClick={ () => { approveRedeem(row.rmid); } } className={ Themes.ImageButton } alt="checkicon" />
                            : null
                    },
                    {
                        name: 'Updated',
                        selector: row => moment(row.updated).format("MMM DD, YYYY"),
                    },
                ] }
                data={ requests }
            />

            <RewardForm isAdd={ adding } data={ details } show={ showDetails } hide={ hideItemDetails } />
        </div>
        
    )
}