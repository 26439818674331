import DataTable from "react-data-table-component";
import moment from "moment";
import { Themes } from "../../common/Constant";
import { formatMoney } from "../../common/Utils";
import { Navigation } from "./Navigation";
import { AdminAddStockist, AdminGetAllStockist, AdminGetStockistPurchases } from "../../common/Request";
import { ShowAlert } from "../UI/Alert";
import { PurchaseForm } from "./form/PurchaseForm";
import { StoreModal } from "./component/StoreModal";
import { useEffect, useRef, useState } from "react";
import editIcon from "../../assets/img/edit.svg";
import storeIcon from "../../assets/img/store.svg";
import { useStoreState } from "../../common/Store";
// import { StockistTable } from "./tables/StockistTable";

export const AdminStockist = () => {

    const userInfo = useStoreState("userinfo");
    const [ index, setIndex ] = useState(0);
    const emailRef = useRef();
    const nameRef = useRef();
    const addressRef = useRef();
    const [ data, setData ] = useState([]);
    const [ purchases, setPurchases ] = useState([]);

    const [ pid, setPid ] = useState(0);
    const [ showDetails, setShowDetails ] = useState(false);

    const [ mid, setMid ] = useState(0);
    const [ showStore, setShowStore ] = useState(false);

    const showPurchaseDetails = (data) => {
        setPid(data);
        setShowDetails(true);
    }

    const hidePurchaseDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            AdminGetStockistPurchases(res => {
                setPurchases(res);
            })
        }
    }

    const clickStore = (mid) => {
        setMid(mid);
        setShowStore(true);
    }

    const columns = [
        {
            name: 'Store Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Owner',
            selector: row => row.owner,
            sortable: true,
        },
        {
            name: 'Contact #',
            selector: row => row.phone,
        },
        {
            name: 'Store Location',
            selector: row => row.address,
            sortable: true,
        },
        {
            name: 'Action',
            selector: row => <div>
                <img src={ storeIcon } onClick={ () => { clickStore(row.mid); } } className={ Themes.ImageButton } alt="storeicon" />
            </div>,
        },
        {
            name: 'Updated',
            selector: row => moment(row.updated).format("MMM DD, YYYY"),
        },
    ];

    const addStockist = (e) => {
        AdminAddStockist({
            email: emailRef.current.value,
            name: nameRef.current.value.replace(/"/g, "”").replace(/'/g, "’").replace(/&/g, "and"),
            address: addressRef.current.value.replace(/"/g, "”").replace(/'/g, "’").replace(/&/g, "and")
        }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "New store (stockist) was added.");
                emailRef.current.value = "";
                nameRef.current.value = "";
                addressRef.current.value = "";
                setIndex(index + 1);
            }else{
                ShowAlert(res.t, res.m);
            }
        });
        e.preventDefault();
    }

    useEffect(() => {
        AdminGetAllStockist(res => {
            setData(res);
        });

        AdminGetStockistPurchases(res => {
            setPurchases(res);
        })
    }, [ index ]);

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto p-8">
                {
                    userInfo.type === "Admin" ?
                        <div>
                            <div className="w-full mb-4">
                                <div className="p-4 bg-white">
                                    <h1 className="text-xl text-slate-700">Add New Store (Stockist)</h1>
                                    <form onSubmit={ addStockist } className="flex">
                                        <input ref={ emailRef } type="email" placeholder="Member's Email" className="w-56 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                        <input ref={ nameRef } type="text" placeholder="Store Name" className="w-56 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                                        <input ref={ addressRef } type="text" placeholder="Store Location" className="w-56 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                                        <button type="submit" className={ Themes.HeaderButton }>+ Add</button>
                                    </form>
                                </div>
                            </div>
                            <DataTable
                                title={
                                    <div>
                                        <h1 className="inline-block text-xl text-slate-700">Store List</h1>
                                    </div>
                                }
                                pagination
                                columns={ columns }
                                data={ data }
                            />
                        </div>
                    : null
                }
                <DataTable
                    title={ 
                        <div>
                            <h1 className="inline-block text-xl text-slate-700">Purchases</h1>
                        </div>
                     }
                    pagination
                    columns={ [
                        {
                            name: 'Ref #',
                            selector: row => row.ref,
                            sortable: true,
                        },
                        {
                            name: 'Store Name',
                            selector: row => row.name,
                            sortable: true,
                        },
                        {
                            name: 'Owner',
                            selector: row => row.owner,
                            sortable: true,
                        },
                        {
                            name: 'Total Amount',
                            selector: row => <span>&#8369;{ formatMoney(row.total) }</span>,
                        },
                        {
                            name: 'Status',
                            selector: row => <b className={ row.status === "COMPLETED" ? "text-green-600" : row.status === "DECLINED" ? "text-red-600" : "text-amber-500" }>{ row.status }</b>,
                            sortable: true,
                        },
                        {
                            name: 'Action',
                            selector: row => <img src={ editIcon } onClick={ () => { showPurchaseDetails(row.pid); } } className={ Themes.ImageButton } alt="editicon" />
                        },
                        {
                            name: 'Created',
                            selector: row => moment(row.created).format("MMM DD, YYYY"),
                        },
                    ] }
                    data={ purchases }
                />
            </div>
            <PurchaseForm pid={ pid } show={ showDetails } hide={ hidePurchaseDetails } />
            {
                mid > 0 ?
                <StoreModal mid={ mid } show={ showStore } onClose={ () => { setShowStore(false) } } /> 
                : null
            }
        </div>
    )
}