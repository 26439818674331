import moment from "moment";
import { useStoreState } from "../../../common/Store";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { formatMoney, getDeliveryStatusColor } from "../../../common/Utils";
import { PurchaseDetailsModal } from "../component/PurchaseDetailsModal";
import { GetPurchases } from "../../../common/Request";

export const PurchaseTable = () => {

    const userInfo = useStoreState("userinfo");
    const [ showDetails, setShowDetails ] = useState(false);
    const [ pid, setPid ] = useState(0);

    const columns = [
        {
            name: 'Date Created',
            selector: row => moment(row.created).format("MMM DD, YYYY"),
        },
        {
            name: 'Ref #',
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: 'Package/Repeat',
            selector: row => <div>
                {
                    row.renew === 1 ? <b className="text-amber-600">Renew<br /></b> : null
                }
                <b className="text-green-600">{ row.purchase }</b><br />
                {
                    row.products.map((p, i) => (
                        <div className="text-xs" key={ "plist_" + i }>{ p.qty + " - " + p.name }</div>
                    ))
                }
            </div>,
            sortable: true,
        },
        {
            name: 'Total',
            selector: row => <div>&#8369; { formatMoney(row.total) }</div>,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b className={ getDeliveryStatusColor(row.deliverystatus).text }>{ row.deliverystatus }</b>,
        },
        {
            name: 'Action',
            selector: row => <button type="button" onClick={ () => { setPid(parseInt(row.pid)); setShowDetails(true); } } className="transition duration-300 bg-green-500 text-white px-2 py-1 rounded-sm hover:bg-slate-700">
                                View Details
                            </button>
        }
    ];

    const [ data, setData ] = useState([]);

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block">Purchases</h1>
            </div>
        )
    }, []);

    useEffect(() => {
        GetPurchases({ mid: userInfo.mid }, res => {
            setData(res);
        });
    }, [ userInfo ])

    return (
        <div className="w-full mb-4">
            <DataTable
                title={ titleComponent }
                pagination
                columns={ columns }
                data={ data }
            />
            <PurchaseDetailsModal pid={ pid } show={ showDetails } onClose={ () => { setShowDetails(false) } } />
        </div>
        
    )
}