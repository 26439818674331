import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../../../common/Constant";
import { GetPurchaseDetails, AdminDeclinePurchase, AdminConfirmPurchase, AdminUpdateDeliveryStatus, MemberMyStoreUpdateDeliveryStatus, MemberMyStoreDeclinePurchase, MemberMyStoreConfirmPurchase } from "../../../common/Request";
import { formatMoney, getDeliveryStatusColor } from "../../../common/Utils";
import { ShowAlert } from "../../UI/Alert";
import { useEffect, useState } from "react";
import closeIcon from "../../../assets/img/white-x.svg";

export const PurchaseForm = ({ pid, show = false, hide, isStore = false }) => {

    const [ data, setData ] = useState(null);
    const [ points, setPoints ] = useState(0);
    const [ deliveryStatus, setDeliveryStatus ] = useState();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const declineResponse = (res) => {
        if(res.t === "Success") {
            ShowAlert("Success", "Purchase successfully declined.");
            close(true);
        }else{
            ShowAlert("Failed", "Something error on server");
        }
    }

    const decline = () => {
        const c = window.confirm("Are you sure to decline this purchase?");
        if(c) {
            if(isStore) {
                MemberMyStoreDeclinePurchase({ pid: data.pid }, declineResponse);
            }else{
                AdminDeclinePurchase({ pid: data.pid }, declineResponse);
            }
        }
    }

    const confirmResponse = (res) => {
        if(res.t === "Success") {
            ShowAlert("Success", "Purchase was completed.");
            close(true);
        }else{
            ShowAlert(res.t, res.m);
        }
    }

    const confirm = () => {
        const c = window.confirm("Update this purchase as paid?");
        if(c) {
            if(isStore) {
                MemberMyStoreConfirmPurchase({ pid: data.pid }, confirmResponse);
            }else{
                AdminConfirmPurchase({ pid: data.pid }, confirmResponse);
            }
        }
    }

    const changeDeliveryStatus = (e) => {
        if(isStore) {
            MemberMyStoreUpdateDeliveryStatus({ pid: pid, status: e.target.value });
        }else{
            AdminUpdateDeliveryStatus({ pid: pid, status: e.target.value });
        }
        setDeliveryStatus(e.target.value);
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
        if(parseInt(pid) > 0) {
            GetPurchaseDetails({ pid: pid }, res => {
                setDeliveryStatus(res.deliverystatus);
                if(parseInt(res.paid) > 0) {
                    setPoints(parseInt(res.package.points));
                }else{
                    let pts = 0;
                    res.products.forEach(p => {
                        pts += parseInt(p.qty) * parseFloat(p.points);
                    });
                    setPoints(pts);
                }
                setData(res)
            })
        }
    }, [ pid, show, anim ])

    return (
        <div className={ "fixed z-50 inset-0 w-screen h-screen bg-slate-700/50 py-4 overflow-y-auto " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-[400px] w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>Purchase Details</h1>
                <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />
                <div className="p-2">
                    <h5 className="text-md text-right float-right">
                        Ref #: { data ? data.ref : null }<br />
                        <b className={ "text-lg " + (data ? (data.status === "PENDING" ? "text-amber-500" : data.status === "COMPLETED" ? "text-green-600" : "text-red-600") : "text-black") }>{ data ? data.status : null }</b>
                    </h5>
                    <h5 className="text-md inline-block">
                        { data ? (data.customer ? data.customer.name : data.member.name) : null }<br />
                        { data ? (data.customer ? data.customer.phone : data.member.phone) : null }<br />
                        { data ? (data.customer ? data.customer.address : data.member.address) : null }
                    </h5>
                    {
                        data ?
                            parseInt(data.renew) === 1 ?
                            <h1 className="text-2xl text-amber-600 font-bold my-2">RENEWAL</h1>
                            : data.storeobj !== null ?
                            <h1 className="text-2xl text-blue-600 font-bold my-2">STOCKIST PURCHASE</h1>
                            : <h1 className="text-2xl text-slate-700 font-bold my-2">{ data ? (parseInt(data.paid) > 0 ? data.package.name : "Repeat Purchase") : "" }</h1>
                        : null
                    }
                    
                    <div className={ Themes.SubPanel + " mb-2" }>
                        <h3 className={ Themes.SubHeader }>Products Included:</h3>
                        <div className={ Themes.SubBody }>
                            {
                                data ?
                                    data.products.map((p, i) => (
                                        <div className="text-sm" key={ "product_admin_" + i } >
                                            { p.qty + "x - " + p.name }
                                            {
                                                data.storeobj !== "" ?
                                                <i className="float-right text-green-600">&#8369;{ formatMoney(p.subtotal) }</i>
                                                : <i className="float-right font-normal text-amber-600">{ parseInt(p.qty) * parseFloat(p.points) }pts.</i>
                                            }
                                            
                                        </div>
                                    ))
                                : null
                            }
                        </div>
                    </div>

                    <div className="float-right text-right">
                        <h5 className="text-sm text-slate-600 font-bold">Points</h5>
                        <h1 className="text-xl text-amber-600 font-bold ml-4">{ points } pts.</h1>
                        <h5 className="text-sm text-slate-600 font-bold">Subtotal</h5>
                        <h1 className="text-xl text-green-600 font-bold ml-4">&#8369;{ data ? formatMoney(data.subtotal) : 0.00 }</h1>
                        <h5 className="text-sm text-slate-600 font-bold">Discount</h5>
                        <h1 className="text-xl text-red-600 font-bold ml-4">-&#8369;
                            {
                                data ?
                                    data.storeobj === null ?
                                    <span>
                                        { data ? formatMoney(parseFloat(data.subtotal) * parseFloat(data.discount)) : 0.00 } <i>({ (parseFloat(data ? data.discount : 0) * 100).toFixed(2) }%)</i>
                                    </span>
                                    : 
                                    <span>
                                        { formatMoney(parseFloat(data.subtotal) - (parseFloat(data.total) - (parseFloat(data.shipping) + parseFloat(data.fee)))) }
                                    </span>
                                : null
                            }
                            
                        </h1>
                        <h5 className="text-sm text-slate-600 font-bold">Shipping Fee</h5>
                        <h1 className="text-xl text-slate-700 font-bold ml-4">&#8369;{ data ? formatMoney(data.shipping) : 0.00 }</h1>
                        <h5 className="text-sm text-slate-600 font-bold">Admin Fee</h5>
                        <h1 className="text-xl text-slate-700 font-bold ml-4">&#8369;{ data ? formatMoney(data.fee) : 0.00 }</h1>
                    </div>
                    
                    <h5 className="text-sm text-slate-600 font-bold">Delivery Method</h5>
                    <h1 className="text-xl text-slate-700 font-bold">{ data ? data.delivery : "" }</h1>
                    {
                        data ? data.delivery ?
                            <div className="mb-2">
                                <h5 className="text-sm text-slate-600 font-bold">Receiver Details</h5>
                                <h5 className="text-blue-700 leading-5">{ data.name }<br />{ data.address }<br/>{ data.phone }</h5>
                            </div>
                        : null : null
                    }
                    <h5 className="text-sm text-slate-600 font-bold">Mode of Payment</h5>
                    <h1 className="text-xl text-slate-700 font-bold">{ data ? data.modepayment : "" }</h1>
                    <h5 className="text-sm text-slate-600 font-bold">Payment Method</h5>
                    <h1 className="text-xl text-slate-700 font-bold">{ data ? data.paymentmethod : "" }</h1>
                    {
                        data ?
                            data.paymentmethod !== "Cash" && data.paymentmethod !== "Wallet Balance" && data.paymentmethod !== null ?
                                <div>
                                    <h5 className="text-sm text-slate-600 font-bold">{ data.paymentmethod } Reference Number</h5>
                                    <h1 className="text-xl text-slate-700 font-bold">{ data ? data.refnum : "" }</h1>
                                    <a className="text-blue-700 underline" href={ data.screenshot } target="_blank" rel="noreferrer" >Click to view proof of Receipt</a>
                                </div>
                            : null
                        : null
                    }
                    <h5 className="text-sm text-slate-600 font-bold">Notes</h5>
                    <h1 className="text-md text-slate-700 font-bold italic">{ data ? data.note : "" }</h1>
                    <h5 className="text-sm text-slate-600 font-bold mt-2">Change Status</h5>
                    {
                        data ?
                            data.delivery === "Shipping" ?
                            <select className={ getDeliveryStatusColor(deliveryStatus).bg +  ` w-full px-2 py-2 rounded-sm text-white font-bold` } onChange={ changeDeliveryStatus }>
                                <option value={ deliveryStatus } hidden>{ deliveryStatus }</option>
                                <option value="ORDER PLACED">ORDER PLACED</option>
                                <option value="PREPARING TO SHIP">PREPARING TO SHIP</option>
                                <option value="IN TRANSIT">IN TRANSIT</option>
                                <option value="OUT FOR DELIVERY">OUT FOR DELIVERY</option>
                                <option value="DELIVERED">DELIVERED</option>
                            </select>
                            :
                            <select className={ getDeliveryStatusColor(deliveryStatus).bg +  ` w-full px-2 py-2 rounded-sm text-white font-bold` } onChange={ changeDeliveryStatus }>
                                <option value={ deliveryStatus } hidden>{ deliveryStatus }</option>
                                <option value="ORDER PLACED">ORDER PLACED</option>
                                <option value="PREPARING">PREPARING</option>
                                <option value="READY FOR PICKUP">READY FOR PICKUP</option>
                                <option value="DELIVERED">DELIVERED</option>
                            </select>
                        : null
                    }
                    
                    
                    <h5 className="text-sm text-slate-600 font-bold text-right mt-8">Total Amount</h5>
                    <h1 className="text-4xl text-green-600 font-bold text-right ml-4 mb-4">&#8369;{ data ? formatMoney(data.total) : 0.00 }</h1>
                    {
                        data ?
                            data.status === "PENDING" ?
                            <div className="grid gap-2 grid-cols-2">
                                <button type="button" onClick={ () => { decline(); } } className={ Themes.RedButton }>Decline</button>
                                <button type="button" onClick={ () => { confirm(); } } className={ Themes.GreenButton }>Click as Paid</button>
                            </div>
                            : null
                        : null
                    }
                    
                </div>
            </animated.div>
        </div>
    )
}