import { formatMoney } from "../../../common/Utils";
import { useEffect, useState } from "react";
import xIcon from "../../../assets/img/x-mark.svg";

export const PayoutModal = ({ data, show = false, onClose }) => {

    const [ front, setFront ] = useState(false);

    useEffect(() => {

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

    }, [ show ])

    return (
        <div data-front={ front }
            className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
                <div data-show={ show } 
                    className="transition ease-linear duration-300 w-full max-w-screen-sm mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4"
                    >
                    <div className="bg-slate-700 py-2 px-4">
                        <h1 className="text-white text-xl inline">Payout Details</h1>
                        <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                    </div>
                    <div className="bg-white p-4">
                        {
                            data && parseFloat(data.fee) === 0 ?
                            <h1 className="bg-gradient-to-b from-teal-400 to-teal-600 text-2xl font-bold text-white mb-2 text-center p-1">Store Payout</h1>
                            : null
                        }
                        {
                            data ?
                                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                                    <div>
                                        <h5 className="text-sm text-slate-700">Method</h5>
                                        <h1 className="text-2xl font-bold text-blue-700 ml-4 mb-2">{ data.method }</h1>
                                        {
                                            data.method !== "Over-The-Counter" ?
                                                <div>
                                                    <h5 className="text-sm text-slate-700">Receiver Details</h5>
                                                    <h1 className="text-md font-bold text-blue-700 ml-4">{ data.acctname }</h1>
                                                    <h1 className="text-md font-bold text-blue-700 ml-4 mb-2">{ data.acctnum }</h1>
                                                </div>
                                                
                                            : null
                                        }
                                        <h5 className="text-sm text-slate-700">Request Amount</h5>
                                        <h1 className="text-2xl font-bold text-slate-700 ml-4 mb-2">&#8369; { formatMoney(data.amount) }</h1>
                                        <h5 className="text-sm text-slate-700">Admin Fee</h5>
                                        <h1 className="text-2xl font-bold text-red-600 ml-4 mb-2">-&#8369; { formatMoney(data.fee) }</h1>
                                        <h5 className="text-sm text-slate-700">Net Payout Amount</h5>
                                        <h1 className="text-4xl font-bold text-green-600 ml-4 mb-4">&#8369; { formatMoney(parseFloat(data.amount) - parseFloat(data.fee)) }</h1>
                                    </div>
                                    <div>
                                        <div>
                                            {
                                                data.method !== "Over-The-Counter" ?
                                                    <div className="mb-4">
                                                        
                                                        {
                                                            data.screenshot !== null ?
                                                            <div>
                                                                <h5 className="text-sm text-slate-700">Reference Number</h5>
                                                                <h1 className="text-md font-bold text-blue-700 ml-4">{ data.refnum }</h1>
                                                                <a href={ data.screenshot } target="_blank" rel="noreferrer" className="text-md font-bold text-blue-700 ml-4 underline">Click to view Proof of Receipt</a>
                                                            </div>
                                                            : null
                                                        }
                                                    </div>
                                                : null
                                            }
                                            {
                                                data.status === "PENDING" ? <h1 className="text-2xl font-bold text-amber-600 text-center">PENDING</h1> :
                                                data.status === "DECLINED" ? <h1 className="text-2xl font-bold text-red-600 text-center">DECLINED</h1> :
                                                <h1 className="text-2xl font-bold text-green-600 text-center">COMPLETED</h1>
                                            }
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </div>
        </div>
    )
}