import { useStoreState, dispatch } from "./common/Store";
import { parseURLParam } from "./common/Utils";
import { Login } from "./component/Login";
import { Register } from "./component/Register";
import { ResetPassword } from "./component/ResetPassword";
import { MemberDashboard } from "./component/Member/MemberDashboard";
import { MemberProducts } from "./component/Member/MemberProducts";
import { MemberBinary } from "./component/Member/MemberBinary";
import { MemberHistory } from "./component/Member/MemberHistory";
import { MemberWallet } from "./component/Member/MemberWallet";
import { PackagePurchase } from "./component/Member/form/PackagePurchase";
import { ClaimRewards } from "./component/Member/form/ClaimRewards";
import { RenewPurchase } from "./component/Member/form/RenewPurchase";

import { AdminDashboard } from "./component/Admin/AdminDashboard";
import { AdminMemberList } from "./component/Admin/AdminMemberList";
import { AdminMemberHistory } from "./component/Admin/AdminMemberHistory";
import { AdminPayoutRequests } from "./component/Admin/AdminPayoutRequests";
import { AdminPackagePurchase } from "./component/Admin/AdminPackagePurchase";
import { AdminRepeatPurchase } from "./component/Admin/AdminRepeatPurchase";
import { AdminRewardPoints } from "./component/Admin/AdminRewardPoints";
import { AdminStockist } from "./component/Admin/AdminStockist";
import { AdminInventory } from "./component/Admin/AdminInventory";
import { AdminSettings } from "./component/Admin/AdminSettings";
import { Alert } from "./component/UI/Alert";
import { useEffect } from "react";

function App() {

	const page = useStoreState("page");

	useEffect(() => {
		const param = parseURLParam();
		if(param['forgotpassword']) {
			dispatch({ type: "page", data: 'resetpassword' });
		}else if(param['ref']) {
			dispatch({ type: "page", data: 'register' });
		}
	}, []);

  	return (
		<div className="h-screen bg-slate-700 font-sans overflow-x-hidden">
			{
				page === "login" ? <Login /> :
				page === "register" ? <Register /> :
				page === "resetpassword" ? <ResetPassword /> :

				page === "memberdashboard" ? <MemberDashboard /> :
				page === "memberproducts" ? <MemberProducts /> :
				page === "memberbinary" ? <MemberBinary /> :
				page === "memberhistory" ? <MemberHistory /> :
				page === "memberwallet" ? <MemberWallet /> :
				page === "packagepurchase" ? <PackagePurchase /> :
				page === "claimrewards" ? <ClaimRewards /> :
				page === "renewpurchase" ? <RenewPurchase /> :

				page === "admindashboard" ? <AdminDashboard /> :
				page === "adminmemberlist" ? <AdminMemberList /> :
				page === "adminmemberhistory" ? <AdminMemberHistory /> :
				page === "adminpayout" ? <AdminPayoutRequests /> :
				page === "adminpackagepurchase" ? <AdminPackagePurchase /> :
				page === "adminrepeatpurchase" ? <AdminRepeatPurchase /> :
				page === "adminrewardpoints" ? <AdminRewardPoints /> :
				page === "adminstore" ? <AdminStockist /> :
				page === "adminproductinventory" ? <AdminInventory /> :
				page === "adminsettings" ? <AdminSettings /> :

				null
			}
			<Alert />
		</div>
  	);
}

export default App;
