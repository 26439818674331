import { createElement, useEffect, useState } from "react";
import { GetBinary, GetDirects } from "../../common/Request";
import { useStoreState } from "../../common/Store";
import { Themes } from "../../common/Constant";
import { Navigation } from "./Navigation";
import { MemberCart } from "./MemberCart";
import user from "../../assets/img/user-white.svg";
import resetIcon from "../../assets/img/reset-white.svg";

export const MemberBinary = () => {

    const userInfo = useStoreState("userinfo");
    const [ data, setData ] = useState();
    const [ directs, setDirects ] = useState();

    const reset = () => {
        getData(userInfo.mid);
    }

    const getData = (mid) => {
        GetBinary({ mid: mid }, res => {
            setData(res);
        });
    }

    const renderDirects = () => {
        if(!directs) return;
        const element = createElement("div", { className: "drop-shadow-[0_2px_0px_rgba(0,0,0,1)]" }, 
            createElement("div", { className: "text-xs w-32 bg-slate-200 py-1 px-1 inline-block border-t-2 border-r-2 border-slate-700", id: userInfo.code }, <div><b>{ userInfo.name }</b><br />{ userInfo.code }</div>)
        );
        setTimeout(() => {
            Object.keys(directs).forEach(d => {
                if(document.getElementById(d)) {
                    directs[d].forEach((l, i) => {
                        if(!document.getElementById(l.code)) {
                            const div = document.createElement("div");
                            div.setAttribute("class", "text-xs w-32 bg-slate-200 py-1 px-1 ml-16 inline-block border-t-2 border-r-2 border-slate-700");
                            div.setAttribute("id", l.code);
                            div.innerHTML = '<b>' + (i + 1) + ". " + l.name + '</b><br />' + l.code;
                            document.getElementById(d).appendChild(div);
                        }
                    })
                }
            });
        }, 500);
        return element;
    }

    useEffect(() => {
        getData(userInfo.mid);
        GetDirects({ mid: userInfo.mid }, res => {
            setDirects(res);
        })
    }, [ userInfo ]);

    return (
        <div>
            <Navigation />
            <MemberCart />
            <button type="button" className={ Themes.HeaderButton + " m-4" } onClick={ () => { reset(); } }>
                <img src={ resetIcon } className={ Themes.HeaderButtonImage } alt="reseticon" />
                Reset
            </button>
            {
                data ?
                <div className="mx-auto text-center my-4">
                    <img src={ user } className={ "w-12 mx-auto bg-" + ( data.first.atid === "2" ? "amber" : "green" ) + "-500 rounded-full" } alt="usericon" />
                    <h5 className="text-white text-sm font-bold">{ data.first.name }</h5>
                    <h5 className="text-white text-xs">{ data.first.code }</h5>
                    <div className="grid gap-2 grid-cols-2">
                        <h5 className="text-white text-xs text-right">Left: <b>{ data.first.lefttotal }</b> <br /><b>{ data.first.leftval }</b></h5>
                        <h5 className="text-white text-xs text-left">Right: <b>{ data.first.righttotal }</b> <br /><b>{ data.first.rightval }</b></h5>
                    </div>
                </div> 
                : null
            }
            {
                data ?
                <div className="grid gap-2 grid-cols-2 mx-auto text-center my-4">
                    {
                        data.second.map((d, i) => (
                            d !== null ?
                            <div className="mx-auto text-center my-4" key={ d.code } >
                                <img src={ user } className={ "w-12 mx-auto bg-" + ( d.atid === "2" ? "amber" : "green" ) + "-500 rounded-full cursor-pointer" } 
                                    onClick={ () => { getData(d.mid) } }
                                    alt="usericon" />
                                <h5 className="text-white text-sm font-bold">{ d.name }</h5>
                                <h5 className="text-white text-xs">{ d.code }</h5>
                                <div className="grid gap-2 grid-cols-2">
                                    <h5 className="text-white text-xs text-right">Left: <b>{ d.lefttotal }</b> <br /><b>{ d.leftval }</b></h5>
                                    <h5 className="text-white text-xs text-left">Right: <b>{ d.righttotal }</b> <br /><b>{ d.rightval }</b></h5>
                                </div>
                            </div>
                            :
                            <div className="mx-auto text-center my-4" key={ "available_" + i } >
                                <img src={ user } className={ "w-12 mx-auto bg-slate-500 rounded-full" } alt="usericon" />
                            </div>
                        ))
                    }
                </div>
                : null
            }
            {
                data ?
                <div className="grid gap-2 grid-cols-4 mx-auto text-center my-4">
                    {
                        data.third.map((d, i) => (
                            d !== null ?
                            <div className="mx-auto text-center my-4" key={ d.code } >
                                <img src={ user } className={ "w-12 mx-auto bg-" + ( d.atid === "2" ? "amber" : "green" ) + "-500 rounded-full cursor-pointer" } 
                                    onClick={ () => { getData(d.mid) } }
                                    alt="usericon" />
                                <h5 className="text-white text-sm font-bold">{ d.name }</h5>
                                <h5 className="text-white text-xs">{ d.code }</h5>
                                <div className="grid gap-2 grid-cols-2">
                                    <h5 className="text-white text-xs text-right">Left: <b>{ d.lefttotal }</b> <br /><b>{ d.leftval }</b></h5>
                                    <h5 className="text-white text-xs text-left">Right: <b>{ d.righttotal }</b> <br /><b>{ d.rightval }</b></h5>
                                </div>
                            </div>
                            :
                            <div className="mx-auto text-center my-4" key={ "available_" + i } >
                                <img src={ user } className={ "w-12 mx-auto bg-slate-500 rounded-full" } alt="usericon" />
                            </div>
                        ))
                    }
                </div>
                : null
            }

            <div className="bg-white p-4 my-4 w-full overflow-x-auto">
                <h1 className="text-xl font-bold text-slate-700 mb-4">My Directs</h1>
                {
                    renderDirects()
                }
            </div>
        </div>
    )
}