import { GetPurchaseDetails } from "../../../common/Request";
import { formatMoney, getDeliveryStatusColor } from "../../../common/Utils";
import { useEffect, useState } from "react";
import xIcon from "../../../assets/img/x-mark.svg";

export const PurchaseDetailsModal = ({ pid = 0, show = false, onClose }) => {

    const [ front, setFront ] = useState(false);
    const [ data, setData ] = useState(null);
    const [ items, setItems ] = useState([]);
    const [ points, setPoints ] = useState(0);

    useEffect(() => {

        if(pid > 0) {
            GetPurchaseDetails({ pid: pid }, res => {
                setData(res);
                setItems(res.products);
                let p = 0;
                res.products.forEach(item => {
                    p += parseInt(item.qty) * parseInt(item.points);
                });
                setPoints(p);
            })
        }

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

    }, [ pid, show ])

    return (
        <div data-front={ front }
            className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
            <div data-show={ show } 
                className="transition ease-linear duration-300 w-full max-w-screen-sm mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4"
                >
                <div className="bg-slate-700 py-2 px-4">
                    <h1 className="text-white text-xl inline">Order Ref # { data !== null ? data.ref : "" }</h1>
                    <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                </div>
                <div className="bg-white p-4">
                    {
                        data ? 
                            parseInt(data.renew) === 1 ?
                                <h1 className="text-blue-600 text-xl font-bold">Account Renewal</h1>
                            : parseInt(data.paid) > 0 ?
                                <h1 className="text-blue-600 text-xl font-bold">Account Activation</h1>
                            : null
                        : null
                    }
                    {
                        data ? 
                            data.store ?
                                <h1 className="text-amber-600 text-md font-bold my-2"><span className="text-slate-700">Source:</span> { data.store.name } ({ data.store.address })</h1>
                            : null
                        : null
                    }
                    <b className="text-slate-700">Purchase Details</b>
                    <table className="text-xs w-full">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items.map((item, i) => (
                                    <tr key={ "item_" + i }>
                                        <td>{ item.name }</td>
                                        <td className="text-right">{ formatMoney(item.price) }</td>
                                        <td className="text-center">{ item.qty }</td>
                                        <td className="text-right">{ formatMoney(parseInt(item.qty) * parseFloat(item.price)) }</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>

                    { data !== null ? 
                        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                            <div>
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Delivery Method:</b>
                                    <div>{ data.delivery }</div>
                                </div>
                                {
                                    data.deliverymethod === "Shipping" ?
                                        <div className="text-sm my-2">
                                            <b className="text-slate-700">Receiver Details:</b>
                                            <div>
                                                { data.name }<br/>{ data.address }<br/>{ data.phone }
                                            </div>
                                        </div>
                                    : null
                                }
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Mode of Payment:</b>
                                    <div>{ data.modepayment } <b className="text-blue-700">{ data.cashier_name !== "" ? "(" + data.cashier_name + ")" : null }</b></div>
                                </div>
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Payment Method:</b>
                                    <div>{ data.paymentmethod }</div>
                                {
                                    data.paymentmethod !== "Cash" && data.paymentmethod !== "Wallet Balance" && data.paymentmethod !== null ?
                                        <div>
                                            Reference #: { data.refnum }
                                            <img src={ data.screenshot } className="w-full mb-4" alt="screenshot" />
                                        </div>
                                    : null
                                }
                                </div>
                                <div className="text-sm my-2">
                                    <b className="text-slate-700">Note:</b>
                                    <div>{ data.note }</div>
                                </div>
                            </div>
                            <div className="my-4 text-right">
                                {
                                    data.storeobj === null ?
                                    <h5 className="px-4 text-sm">Total Points: 
                                        <span className="text-slate-700 font-bold text-lg ml-2">{ points } pts.</span>
                                    </h5>
                                    : null
                                }
                                <h5 className="px-4 text-sm">Subtotal: 
                                    <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(data.subtotal) }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Discount: 
                                    {
                                        data.storeobj === null ?
                                        <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(parseFloat(data.subtotal) * parseFloat(data.discount)) } <i>({ formatMoney(parseFloat(data.discount) * 100) }%)</i></span>
                                        :
                                        <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(parseFloat(data.subtotal) - (parseFloat(data.total) - (parseFloat(data.shipping) + parseFloat(data.fee)))) }</span>
                                    }
                                    
                                </h5>
                                <h5 className="px-4 text-sm">Shipping Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(data.shipping) }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Admin Fee: 
                                    <span className="text-black font-bold text-lg ml-2">&#8369;{ formatMoney(data.fee) }</span>
                                </h5>
                                <h5 className="px-4 text-sm mb-4">Total: 
                                    <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(data.total) }</span>
                                </h5>
                                <br/>
                                <h5 className="px-4 text-sm mb-4">Status: 
                                    <span className={ "font-bold text-xl ml-2 " + getDeliveryStatusColor(data.deliverystatus).text }>{ data.deliverystatus }</span>
                                </h5>
                            </div>
                        </div>
                    : null }
                    
                </div>
            </div>
        </div>
    )
}