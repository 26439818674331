import { HistoryTable } from "../../Member/tables/HistoryTable";
import { useEffect, useState } from "react";
import xIcon from "../../../assets/img/x-mark.svg";

export const EarningHistory = ({ mid = 0, name = '', show = false, onClose }) => {

    const [ front, setFront ] = useState(false);

    useEffect(() => {

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

        
    }, [ show ])

    return (
        <div data-front={ front }
            className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
                <div data-show={ show } 
                    className="transition ease-linear duration-300 w-full max-w-screen-sm mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4"
                    >
                    <div className="bg-slate-700 py-2 px-4">
                        <h1 className="text-white text-xl inline">{ name }'s Earnings History</h1>
                        <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                    </div>
                    <div className="bg-white p-4">
                        {
                            mid > 0 ?
                            <HistoryTable mid={ mid } />
                            : null
                        }
                    </div>
                </div>
        </div>
    )

}