import moment from "moment";
import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../common/Constant";
import { AdminUpgradeMember, AdminUpgradeRank, GetAdminMemberDetails, AdminUpdateMemberDetails } from "../common/Request";
import { ShowAlert } from "./UI/Alert";
import userIcon from "../assets/img/user.svg";
import locationIcon from "../assets/img/location.svg";
import emailIcon from "../assets/img/email.svg";
import phoneIcon from "../assets/img/phone.svg";
import sponsorIcon from "../assets/img/sponsor.svg";
import uplineIcon from "../assets/img/upline.svg";
import leftRightIcon from "../assets/img/left-right.svg";
import closeIcon from "../assets/img/white-x.svg";
import { useEffect, useRef, useState } from "react";
import { useStoreState } from "../common/Store";

export const UserDetails = ({ data = {}, show = false, hide, onupdate }) => {

    const userInfo = useStoreState("userinfo");
    const [ details, setDetails ] = useState(data);
    const [ key, setKey ] = useState("user_" + moment().format("x"));
    const nameRef = useRef();
    const emailRef = useRef();
    const addressRef = useRef();
    const phoneRef = useRef();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const upgradeToWholesaler = () => {
        const confirm = window.confirm("Are you sure to upgrade this account from Reseller to Wholesaler?");
        if(confirm) {
            AdminUpgradeMember({ mid: details.mid }, res => {
                if(res.t !== "Success") {
                    ShowAlert(res.t, res.m);
                }else{
                    close(true);
                }
            })
        }
    }

    const upgradeRank = (e) => {
        AdminUpgradeRank({ mid: data.mid, rid: e.target.value }, res => {
            if(res.t !== "Success") {
                ShowAlert(res.t, res.m);
            }
        })
    }

    const onSubmit = (e) => {
        AdminUpdateMemberDetails({
            mid: details.mid,
            name: nameRef.current.value,
            email: emailRef.current.value,
            address: addressRef.current.value,
            phone: phoneRef.current.value
        }, res => {
            if(res.t !== "Success") {
                ShowAlert(res.t, res.m);
            }else{
                ShowAlert("Success", "Changes was saved!");
                onupdate();
            }
        })

        e.preventDefault();
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
        if(data.mid !== undefined) {
            GetAdminMemberDetails({ mid: data.mid }, res => {
                setDetails(res);
                setKey("user_" + res.mid + "_" + moment().format("x"));
            })
        }
    }, [ show, anim, data ])

    return (
        <div className={ "fixed inset-0 w-screen h-screen bg-slate-700/50 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    Member Details
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2" key={ key }>
                    <h5 className="text-sm font-bold">Account Type:</h5>
                    <div className="relative">
                        <img src={ userIcon } className={ Themes.InputIcon } alt="spoicon" />
                        <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ details.type } readOnly />
                        {
                            userInfo.type === "Admin" ?
                                details.type !== "Reseller" ?  
                                <select className="absolute right-0 top-1 text-white bg-slate-700 px-2 py-1" onChange={ (e) => { upgradeRank(e) } }>
                                    <option value="0" selected={ details.rid === "0" }>Select Rank</option>
                                    <option value="1" selected={ details.rid === "1" }>Team Leader</option>
                                    <option value="2" selected={ details.rid === "2" }>Team Manager</option>
                                    <option value="3" selected={ details.rid === "3" }>Sales Director</option>
                                </select>
                                : <button type="button" className={ Themes.InputButtonRight } onClick={ () => { upgradeToWholesaler() } }>Activate to Wholesaler</button>
                            : null
                        }
                    </div>
                    <div className="grid grid-cols-2">
                        <div>
                            <h5 className="text-sm font-bold">Account Code:</h5>
                            <div className="relative">
                                <img src={ userIcon } className={ Themes.InputIcon } alt="spoicon" />
                                <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ details.code } readOnly />
                            </div>
                        </div>
                        <div>
                            <h5 className="text-sm font-bold">Sponsor:</h5>
                            <div className="relative">
                                <img src={ sponsorIcon } className={ Themes.InputIcon } alt="spoicon" />
                                <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ details.sponsor } readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2">
                        <div>
                            <h5 className="text-sm font-bold">Upline:</h5>
                            <div className="relative">
                                <img src={ uplineIcon } className={ Themes.InputIcon } alt="upicon" />
                                <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ details.upline } readOnly />
                            </div>
                        </div>
                        <div>
                            <h5 className="text-sm font-bold">Position:</h5>
                            <div className="relative mb-4">
                                <img src={ leftRightIcon } className={ Themes.InputIcon } alt="lricon" />
                                <input type="text" className={ Themes.InputField + " bg-gray-100" } defaultValue={ details.pos } readOnly />
                            </div>
                        </div>
                    </div>
                    <h5 className="text-sm font-bold">Name:</h5>
                    <div className="relative">
                        <img src={ userIcon } className={ Themes.InputIcon } alt="usericon" />
                        <input type="text" ref={ nameRef } className={ Themes.InputField } defaultValue={ details.name } required />
                    </div>
                    <h5 className="text-sm font-bold">Phone:</h5>
                    <div className="relative">
                        <img src={ phoneIcon } className={ Themes.InputIcon } alt="phoneicon" />
                        <input type="text" ref={ phoneRef } className={ Themes.InputField } defaultValue={ details.phone } required />
                    </div>
                    <h5 className="text-sm font-bold">Email:</h5>
                    <div className="relative">
                        <img src={ emailIcon } className={ Themes.InputIcon } alt="emailicon" />
                        <input type="email" ref={ emailRef } className={ Themes.InputField } defaultValue={ details.email } required />
                    </div>
                    <h5 className="text-sm font-bold">Address:</h5>
                    <div className="relative">
                        <img src={ locationIcon } className={ Themes.InputIcon } alt="locicon" />
                        <input type="text" ref={ addressRef } className={ Themes.InputField } defaultValue={ details.address } required />
                    </div>
                    <button type="submit" className={ Themes.FilledButton }>Save Changes</button>
                </form>
            </animated.div>
        </div>
    )
}