import moment from "moment";
import DataTable from "react-data-table-component";
import { useStoreState } from "../../../common/Store";
import { Themes } from "../../../common/Constant";
import { ShowAlert } from "../../UI/Alert";
import { CheckoutModal } from "./CheckoutModal";
import { formatMoney } from "../../../common/Utils";
import { MemberMyStore, MemberMyStoreInventory, MemberMyStoreTransactions, MemberMyStoreProductsAndPackages, GetDiscount, GetAllBankAccounts, AddBankAccount, DeleteBankAccount, SendPayoutOTP, StorePayoutRequest } from "../../../common/Request";
import { PurchaseForm } from "../../Admin/form/PurchaseForm";
import { TabPanel } from "../../UI/TabPanel";
import { useEffect, useRef, useState } from "react";
import xIcon from "../../../assets/img/x-mark.svg";
import editIcon from "../../../assets/img/edit.svg";
import check from "../../../assets/img/check-white.svg";

export const MyStoreModal = ({ show = false, storeWallet = 0, onClose }) => {

    const userInfo = useStoreState("userinfo");
    const [ front, setFront ] = useState(false);
    const [ data, setData ] = useState({name: "", address: "", obj: []});
    const [ inventory, setInventory ] = useState([]);
    const [ products, setProducts ] = useState([]);
    const [ packages, setPackages ] = useState([]);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ discount, setDiscount ] = useState(0);
    const [ memberDiscount, setMemberDiscount ] = useState(0);
    const [ purchaseData, setPurchaseData ] = useState([]);
    const [ transactions, setTransactions ] = useState([]);
    const [ showCheckout, setShowCheckout ] = useState(false);
    const [ tabIndex, setTabIndex ] = useState(0);

    const [ pid, setPid ] = useState(0);
    const [ showDetails, setShowDetails ] = useState(false);

    const [ banks, setBanks ] = useState([]);
    const bankNameRef = useRef();
    const bankAcctRef = useRef();
    const bankNumRef = useRef();

    const [ method, setMethod ] = useState(null);
    const [ otpSent, setOtpSent ] = useState(false);
    const acctNameRef = useRef();
    const acctNumRef = useRef();
    const otpRef = useRef();

    const showPurchaseDetails = (pid) => {
        setPid(pid);
        setShowDetails(true);
    }

    const hidePurchaseDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            MemberMyStoreTransactions({ mid: userInfo.mid }, res => {
                setTransactions(res)
            });
        }
    }

    const onChangeQty = (e) => {
        const d = e.target.dataset;
        const dat = JSON.parse(d.data);
        const qty = parseInt(e.target.value);
        const isRepeat = d.type === "Repeat";
        const price = parseFloat(isRepeat ? dat.price : dat.total);
        const storemin = parseInt(dat.storemin);
        const sub = price * qty;
        let data = purchaseData, exist = false, totalDisc = 0, total = 0, disc = 0;

        if(qty >= storemin) {
            disc = isRepeat ? sub * parseFloat(userInfo.storediscount) : parseFloat(userInfo.storepackdisc) * qty;
        }else if(isRepeat) {
            disc = sub * memberDiscount;
        }

        data.forEach((p, i) => {
            if(p.id === (isRepeat ? dat.prid : dat.paid) && p.type === d.type) {
                p.qty = qty;
                p.discount = disc;
                p.subtotal = sub;
                p.total = sub - disc;
                exist = true;
                if(qty === 0) {
                    data.splice(i, 1);
                }
            }
        })
        if(!exist) {
            data.push({ 
                type: d.type, 
                id: isRepeat ? dat.prid : dat.paid, 
                name: dat.name, 
                points: isRepeat ? dat.points : 0, 
                free: isRepeat ? dat.free : 0, 
                price: price, 
                qty: qty,
                storemin: storemin,
                subtotal: sub,
                discount: disc,
                total: sub - disc
            });
        }
        data.forEach(p => {
            totalDisc += p.discount;
            total += p.subtotal;
        })
        setPurchaseData(data);
        setDiscount(totalDisc);
        setSubtotal(total);
    }

    const getTotal = (type, id) => {
        let total = 0;
        purchaseData.forEach(d => {
            if(d.type === type && d.id === id) {
                total = d.total;
            }
        })
        return total;
    }

    const checkout = (e) => {
        if(purchaseData.length === 0) {
            ShowAlert("Checkout", "Can't proceed, no item/s selected to checkout.");
            return;
        }
        setShowCheckout(true);
    }

    const renderBankAccounts = () => {
        GetAllBankAccounts({ mid: userInfo.mid }, res => {
            setBanks(res);
        })
    }

    const addBankAccount = (e) => {
        AddBankAccount({ 
            mid: userInfo.mid,
            bankname: bankNameRef.current.value, 
            acctname: bankAcctRef.current.value, 
            acctnum: bankNumRef.current.value}, res => {
            if(res.t === "Success") {
                ShowAlert("Success", bankNameRef.current.value + " was added to your bank/e-wallet details.");
                renderBankAccounts();
            }else{
                ShowAlert("Failed", "Error found when adding new bank details.");
            }
        })
        e.preventDefault();
    }

    const deleteBankAccount = (id) => {
        const c = window.confirm("Are you sure to delete the selected bank details?");
        if(c) {
            DeleteBankAccount({ bankid: id }, res => {
                if(res.t === "Success") {
                    renderBankAccounts();
                }else{
                    ShowAlert("Failed", "Error found when deleting bank details.");
                }
            })
        }
    }

    const changeMethod = (e) => {
        setMethod(e.target.value);
    }

    const sendOTP = (e) => {
        SendPayoutOTP({ mid: userInfo.mid }, res => {
            if(res.t === "Success") {
                ShowAlert("Validate OTP", "Your One-time code has been sent to your email. Please enter the OTP you received to validate.");
                setOtpSent(true);
            }else{
                ShowAlert(res.t, res.m);
            }
        })
    }

    const storeRequestPayout = (e) => {
        const c = window.confirm("Request for payout?");
        if(c) {
            StorePayoutRequest({ 
                mid: userInfo.mid,
                method: method,
                acctname: method !== "Over-The-Counter" ? acctNameRef.current.value : "",
                acctnum: method !== "Over-The-Counter" ? acctNumRef.current.value : "",
                otp: otpRef.current.value
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "Request payout sent!, please proceed to cashier to encash.");
                    onClose();
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }
        e.preventDefault();
    }

    useEffect(() => {
        GetDiscount({ mid: userInfo.mid }, res => {
            setMemberDiscount(parseFloat(res.discount));
        });

        MemberMyStore({ mid: userInfo.mid }, res => {
            setData(res);
        });

        MemberMyStoreInventory({ mid: userInfo.mid }, res => {
            setInventory(res);
        });

        MemberMyStoreProductsAndPackages(res => {
            setProducts(res.Products);
            setPackages(res.Packages);
        });

        MemberMyStoreTransactions({ mid: userInfo.mid }, res => {
            setTransactions(res)
        });

        GetAllBankAccounts({ mid: userInfo.mid }, res => {
            setBanks(res);
        });

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

    }, [ show, userInfo ])

    return (
        <div data-front={ front } className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
            <div data-show={ show } className="transition ease-linear duration-300 w-full max-w-[800px] mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4">
                <div className="bg-gradient-to-r from-teal-400 to-teal-600 py-2 px-4">
                    <h1 className="text-white text-xl inline">{ data.name }</h1>
                    <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                </div>
                <div className="bg-teal-50 p-4">
                    <TabPanel tabs={ ['Inventory', 'Transactions', 'Buy', 'Bank/E-Wallet', 'My Store Wallet'] } onChange={ (index) => { setTabIndex(index) } } />
                    <br />
                {
                    tabIndex === 0 ?
                    <div>
                        <h1 className="text-slate-700 text-lg">Inventory Status List</h1>
                        <div>
                            <DataTable
                                pagination
                                columns={ [
                                    { name: 'Item / Package Name', selector: row => <div>{ row.name }{ row.type === "Package" ? " Package" : null }</div> },
                                    { name: 'Price', selector: row => <div>&#8369; { formatMoney(row.price) }</div>, sortable: true },
                                    { name: 'Stock', selector: row => row.stock, sortable: true }
                                ] }
                                data={ inventory }
                            />
                        </div>
                    </div>
                    : tabIndex === 1 ?
                    <div>
                        <h1 className="text-slate-700 text-lg">Transaction History</h1>
                        <div>
                            <DataTable
                                pagination
                                columns={ [
                                    { name: 'Date Created', selector: row => moment(row.updated).format("MMM DD, YYYY"), sortable: true },
                                    { name: 'Ref #', selector: row => row.ref },
                                    { name: 'Name', width: '150px', selector: row => row.name, sortable: true },
                                    { 
                                        name: 'Type',
                                        width: '150px',
                                        selector: row => <div><b className="text-amber-600">
                                            {
                                                row.renew === 1 ? "Account Renewal" : row.paid > 0 ? "Package Purchase" : "Repeat Purchase"
                                            }
                                        </b></div>
                                    },
                                    { name: 'Total', selector: row => <div>&#8369; { formatMoney(row.total) }</div>, sortable: true },
                                    { name: 'Action', selector: row => <img src={ editIcon } onClick={ () => { showPurchaseDetails(row.pid); } } className={ Themes.ImageButton } alt="editicon" /> },
                                ] }
                                data={ transactions }
                            />
                        </div>
                    </div>
                    : tabIndex === 2 ?
                    <div>
                        <h1 className="text-slate-700 text-lg">Buy Repeat/Package Products</h1>
                        <div>
                            <table className="w-full text-xs">
                                <thead className="text-slate-700">
                                    <tr>
                                        <th>Item Name</th>
                                        <th>Price</th>
                                        <th>Min. Qty Discount</th>
                                        <th>Quantity</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        packages.map(p => (
                                            <tr key={ "store_package_" + p.paid }>
                                                <td className="font-bold text-amber-600">Package - { p.name }</td>
                                                <td className="text-right">&#8369;{ formatMoney(p.total) }</td>
                                                <td className="text-center w-[150px]">{ p.storemin } (-&#8369;{ formatMoney(userInfo.storepackdisc) })</td>
                                                <td className="text-right w-[120px] p-0 border-2 border-amber-600">
                                                    <input type="number" onChange={ onChangeQty } data-type="Package" data-data={ JSON.stringify(p) } defaultValue="0" min="0" step="1" className="px-2 w-full text-lg text-center" />
                                                </td>
                                                <td className="text-right">&#8369;{ formatMoney(getTotal("Package", p.paid)) }</td>
                                            </tr>
                                        ))
                                    }
                                    {
                                        products.map(p => (
                                            <tr key={ "store_product_" + p.prid }>
                                                <td className="font-bold text-green-700">{ p.name }</td>
                                                <td className="text-right">&#8369;{ formatMoney(p.price) }</td>
                                                <td className="text-center w-[150px]">{ p.storemin } (-{ parseFloat(userInfo.storediscount) * 100 }%)</td>
                                                <td className="text-right w-[120px] p-0 border-2 border-amber-600">
                                                    <input type="number" onChange={ onChangeQty } data-type="Repeat" data-data={ JSON.stringify(p) } defaultValue="0" min="0" step="1" className="px-2 w-full text-lg text-center" />
                                                </td>
                                                <td className="text-right">&#8369;{ formatMoney(getTotal("Repeat", p.prid)) }</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <h5 className="px-4 text-sm">Subtotal: 
                                    <span className="text-amber-600 font-bold text-lg ml-2">&#8369;{ formatMoney(subtotal) }</span>
                                </h5>
                                <h5 className="px-4 text-sm">Discount: 
                                    <span className="text-red-700 font-bold text-lg ml-2">-&#8369;{ formatMoney(discount) }</span>
                                </h5>
                                <h5 className="px-4 text-sm mb-4">Total: 
                                    <span className="text-green-600 font-bold text-2xl ml-2">&#8369;{ formatMoney(subtotal - discount) }</span>
                                </h5>
                            </div>
                            <div className="mt-4 text-right">
                                <button type="button" className={ Themes.HeaderButton } onClick={ checkout }>
                                    <img src={ check } className={ Themes.HeaderButtonImage } alt="checkicon" />
                                    Checkout
                                </button>
                            </div>
                        </div>
                    </div>
                    : tabIndex === 3 ?
                    <div>
                        <h1 className="text-slate-700 text-lg">Bank Accounts / E-Wallet</h1>
                        <form onSubmit={ (e) => { addBankAccount(e); } } className="grid grid-cols-1 gap-2 my-2 md:flex md:gap-1">
                            <select ref={ bankNameRef } className="w-full md:w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required>
                                <option value="">Select Bank Name</option>
                                <option value="BDO">BDO</option>
                                <option value="BPI">BPI</option>
                                <option value="GCash">GCash</option>
                                <option value="Metrobank">Metrobank</option>
                                <option value="Paypal">Paypal</option>
                                <option value="Security Bank">Security Bank</option>
                            </select>
                            <input ref={ bankAcctRef } type="text" placeholder="Account Name" className="w-full md:w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                            <input ref={ bankNumRef } type="text" placeholder="Account Number" className="w-full md:w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                            <button type="submit" className={ Themes.HeaderButton }>+ Add</button>
                        </form>
                        <table className="w-full text-sm my-2">
                            <thead>
                                <tr>
                                    <th>Bank/E-Wallet Name</th>
                                    <th>Account Name</th>
                                    <th>Account Number</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    banks.length === 0 ?
                                    <tr><td colSpan={ 4 } className="text-center p-4">There are no records to display</td></tr> :
                                    banks.map(b => (
                                        <tr key={ "bank_store_" + b.bankid }>
                                            <td>{ b.bankname }</td>
                                            <td>{ b.acctname }</td>
                                            <td>{ b.acctnum }</td>
                                            <td className="text-center"><img src={ xIcon } onClick={ () => { deleteBankAccount(b.bankid); } } className={ Themes.ImageButton } alt="xicon" /></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    :
                    <div>
                        <h1 className="text-slate-700 text-lg">Request Payout for <b className="text-3xl text-green-600">&#8369;{ formatMoney(storeWallet) }</b></h1>
                        <form onSubmit={ (e) => { storeRequestPayout(e); } }>
                            <div className="grid grid-cols-1 gap-2 my-2 md:flex md:gap-1">
                                <select onChange={ changeMethod } className="w-full md:w-full h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required>
                                    <option value="">Sending Method</option>
                                    <option value="Over-The-Counter">Over-The-Counter</option>
                                    <option value="BDO">BDO</option>
                                    <option value="BPI">BPI</option>
                                    <option value="GCash">GCash</option>
                                    <option value="Metrobank">Metrobank</option>
                                    <option value="Security Bank">Security Bank</option>
                                </select>
                                {
                                    method !== "Over-The-Counter" && method !== null && method !== "" ?
                                            <input ref={ acctNameRef } type="text" placeholder="Account Name" className="w-full md:w-full h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    : null
                                }
                                {
                                    method !== "Over-The-Counter" && method !== null && method !== "" ?
                                            <input ref={ acctNumRef } type="text" placeholder="Account Number" className="w-full md:w-full h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    : null
                                }
                            </div>
                            {
                                otpSent ?
                                <h5 className="text-blue-700 text-sm font-bold">Enter the 6-digit verification code that was sent to your email address.</h5>
                                : null
                            }
                            <div className="grid grid-cols-1 gap-2 my-2 md:flex md:gap-1">
                                <input ref={ otpRef } type="text" placeholder="One-time Pin" className="w-full md:w-full h-10 border-2 text-sm border-blue-600 px-2 py-1 rounded-lg" required />
                                <button type="button" onClick={ sendOTP } className={ Themes.HeaderButton + " w-full !mx-0 !bg-blue-600 !border-blue-600" }>Send OTP to your Email</button>
                                <button type="submit" className={ Themes.HeaderButton + " w-full !mx-0" }>Send Request</button>
                            </div>
                            
                        </form>
                    </div>
                }
                </div>
            </div>
            <CheckoutModal data={ purchaseData } type="Store" show={ showCheckout } onClose={ () => { setShowCheckout(false) } } />
            <PurchaseForm pid={ pid } show={ showDetails } hide={ hidePurchaseDetails } isStore={ true } />
        </div>
    )
}