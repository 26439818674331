import logo from "../../assets/img/logo.png";
import { dispatch, useStoreState } from "../../common/Store";

export const Navigation = () => {

    const userInfo = useStoreState("userinfo");
    const page = useStoreState("page");
    const links = userInfo.type === "Admin" ? [
        ["Dashboard", "admindashboard"], 
        ["Member List", "adminmemberlist"], 
        ["Member History", "adminmemberhistory"],
        ["Payout Requests", "adminpayout"], 
        ["Package Purchase", "adminpackagepurchase"], 
        ["Repeat Purchase", "adminrepeatpurchase"], 
        ["Reward Points", "adminrewardpoints"], 
        ["Store (Stockist)", "adminstore"], 
        ["Product Inventory", "adminproductinventory"], 
        ["Settings", "adminsettings"], 
        ["Logout", "login"]
    ] : [
        ["Member List", "adminmemberlist"], 
        ["Payout Requests", "adminpayout"], 
        ["Package Purchase", "adminpackagepurchase"], 
        ["Repeat Purchase", "adminrepeatpurchase"], 
        ["Reward Points", "adminrewardpoints"], 
        ["Store (Stockist)", "adminstore"], 
        // ["Product Inventory", "adminproductinventory"], 
        // ["Settings", "adminsettings"], 
        ["Logout", "login"]
    ];

    const clickSideMenu = (p) => {
        dispatch({ type: "page", data: p });
    }

    return (
        <div className="w-52 h-screen bg-white drop-shadow-lg py-4 flex-none">
            <img src={ logo } className="w-24 mx-auto" alt="logo" />
            <h1 className="text-2xl text-center text-slate-700 my-10 uppercase">{ userInfo.type }</h1>
            {
                    links.map(l => (
                        <button className={ 
                                "block w-full text-md p-2 transition-all ease-linear duration-300 md:my-0" +
                                (page === l[1] ? " text-white bg-amber-500 scale-110" : " text-slate-700 bg-gray-100")
                            } 
                            type="button"
                            onClick={ () => { clickSideMenu(l[1]) } } 
                            key={ l[1] }>
                                { l[0] }
                        </button>
                    ))
                }
        </div>
    )
}