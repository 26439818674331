import { Navigation } from "./Navigation";
import { MemberCart } from "./MemberCart";
import { WalletTable } from "./tables/WalletTable";

export const MemberWallet = () => {

    return (
        <div>
            <Navigation />
            <MemberCart />
            <div className="m-4">
                <WalletTable />
            </div>
        </div>
    )
}