import { dispatch, useStoreState } from "../../../common/Store";
import { GetPackages } from "../../../common/Request";
import { Themes } from "../../../common/Constant";
import { formatMoney } from "../../../common/Utils";
import { CheckoutModal } from "../component/CheckoutModal";
import { Navigation } from "../Navigation";
import { useEffect, useRef, useState } from "react";
import bagIcon from "../../../assets/img/shopping-bag.svg";

export const PackagePurchase = () => {

    const packageRef = useRef();
    const userInfo = useStoreState("userinfo");
    const [ packages, setPackages ] = useState([]);
    const [ showCheckout, setShowCheckout ] = useState(false);
    const [ products, setProducts ] = useState([]);
    const [ points, setPoints ] = useState(0);
    const [ subtotal, setSubtotal ] = useState(0);
    const [ discount, setDiscount ] = useState(0);
    const [ total, setTotal ] = useState(0);

    const selectPackage = (e) => {
        packages.forEach(p => {
            if(p.paid === e.target.value) {
                let sub = 0;
                p.products.forEach(product => {
                    sub += parseInt(product.qty) * parseFloat(product.price);
                });
                let disc = (sub - parseFloat(p.total)) / sub;
                setProducts(p.products);
                setPoints(p.points);
                setSubtotal(sub);
                setDiscount(disc);
                setTotal(parseFloat(p.total));
            }
        })
    }

    useEffect(() => {
        GetPackages({ mid: userInfo.mid }, res => {
            setPackages(res);
        })
    }, [ userInfo ]);

    return (
        <div>
            <Navigation />
            <div className="bg-white max-w-96 w-screen block m-auto">
                <h1 className={ Themes.Header }>Purchase Package</h1>
                <div className="p-2">
                    <div className="relative mb-2">
                        <img src={ bagIcon } className={ Themes.InputIcon } alt="bagicon" />
                        <select ref={ packageRef } className={ Themes.InputField } onChange={ (e) => { selectPackage(e); } } required>
                            <option value="" hidden>SELECT PACKAGE</option>
                            {
                                packages.map((p, i) => (
                                    <option value={ p.paid } key={ "package_" + i }>{ p.name }</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={ Themes.SubPanel + " mb-2" }>
                        <h3 className={ Themes.SubHeader }>Products Included:</h3>
                        <div className={ Themes.SubBody }>
                            {
                                products.map((p, i) => (
                                    <div className="text-sm" key={ "product_" + i }>{ p.qty + "x - " + p.name }</div>
                                ))
                            }
                        </div>
                    </div>
                    <h5 className="text-sm text-slate-600 font-bold">Points</h5>
                    <h1 className="text-xl text-slate-700 font-bold ml-4">{ points } pts.</h1>
                    <h5 className="text-sm text-slate-600 font-bold">Subtotal</h5>
                    <h1 className="text-xl text-amber-600 font-bold ml-4">&#8369;{ formatMoney(subtotal) }</h1>
                    <h5 className="text-sm text-slate-600 font-bold">Discount</h5>
                    <h1 className="text-xl text-red-600 font-bold ml-4">
                        -&#8369;{ formatMoney(subtotal * discount) } <i>({ (discount * 100).toFixed(2) }%)</i>
                    </h1>
                    <h5 className="text-md text-slate-700 block font-bold mt-4">Total Amount:</h5>
                    <h5 className="text-3xl text-green-600 block font-bold ml-4 mb-4">&#8369;{ formatMoney(total) }</h5>
                    {
                        total > 0 ? 
                            <button type="button" onClick={ () => { setShowCheckout(true) } } className={ Themes.FilledButton }>Checkout</button>
                        : null
                    }
                    <button type="button" className={ Themes.OutlineButton } onClick={ () => { dispatch({ type: "page", data: "memberdashboard" }); } }>Cancel</button>
                </div>
                {
                    total > 0 ? 
                        <CheckoutModal data={ products } type="Package" paid={ packageRef.current.value } packagePoints={ points } packageTotal={ total } show={ showCheckout } onClose={ () => { setShowCheckout(false) } } />
                    : null
                }
            </div>
        </div>
    )

}