import { Navigation } from "./Navigation";
import { RewardItemsTable } from "./tables/RewardItemsTables";

export const AdminRewardPoints = () => {

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto p-8">
                <RewardItemsTable />
            </div>
        </div>
    )
}