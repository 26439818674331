export const Panel = ({ className, borderColor = "border-slate-700", bgColor = "bg-slate-700", title, body, footer = null }) => {

    return (
        <div className={ className }>
            <div className={ "border-2 " + borderColor }>
                <div className={ "text-white p-2 font-bold text-center " + bgColor }>{ title }</div>
                <div className={ "text-white text-center px-2 py-4" }>{ body }</div>
                {
                    footer ? 
                    <div className={ "text-center p-2 " + bgColor }>{ footer }</div> 
                    : null
                }
            </div>
        </div>
        
    )
}