import moment from "moment";
import { Navigation } from "./Navigation";
import { Themes } from "../../common/Constant";
import { GetAllBankAccounts, AddBankAccount, DeleteBankAccount, GetAdminSettings, UpdateSettings, AdminGetAllCashiers, AdminAddCashiers, AdminDeleteCashiers } from "../../common/Request";
import { ShowAlert } from "../UI/Alert";
import { useEffect, useRef, useState } from "react";
import xIcon from "../../assets/img/x-mark.svg";

export const AdminSettings = () => {

    const [ cashiers, setCashiers ] = useState([]);
    const cashierNameRef = useRef();
    const cashierEmailRef = useRef();
    const cashierPasswordRef = useRef();

    const [ banks, setBanks ] = useState([]);
    const bankNameRef = useRef();
    const bankAcctRef = useRef();
    const bankNumRef = useRef();
    
    const [ other, setOther ] = useState(null);
    const adminFeeRef = useRef();
    const otherFeeRef = useRef();
    const shippingFeeRef = useRef();
    const safetyRef = useRef();
    const mrpRef = useRef();
    const storeDiscountRef = useRef();
    const storePackDiscRef = useRef();
    const cashierDailyRef = useRef();

    const renderCashiers = () => {
        AdminGetAllCashiers(res => {
            setCashiers(res);
        });
    }

    const addCashier = (e) => {
        AdminAddCashiers({
            name: cashierNameRef.current.value,
            email: cashierEmailRef.current.value,
            password: cashierPasswordRef.current.value
        }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", cashierNameRef.current.value + " was added.");
                renderCashiers();
                cashierNameRef.current.value = "";
                cashierEmailRef.current.value = "";
                cashierPasswordRef.current.value = "";
            }else{
                ShowAlert("Failed", "Error found when adding new cashier.");
            }
        })
        e.preventDefault();
    }

    const deleteCashier = (id) => {
        const c = window.confirm("Are you sure to delete the selected cashier?");
        if(c) {
            AdminDeleteCashiers({ mid: id }, res => {
                if(res.t === "Success") {
                    renderCashiers();
                }else{
                    ShowAlert("Failed", "Error found when deleting cashier.");
                }
            })
        }
    }

    const renderBankAccounts = () => {
        GetAllBankAccounts({ mid: 0 }, res => {
            setBanks(res);
        })
    }

    const addBankAccount = (e) => {
        AddBankAccount({ 
            mid: 0,
            bankname: bankNameRef.current.value, 
            acctname: bankAcctRef.current.value, 
            acctnum: bankNumRef.current.value}, res => {
            if(res.t === "Success") {
                ShowAlert("Success", bankNameRef.current.value + " was added to your bank/e-wallet details.");
                renderBankAccounts();
                bankNameRef.current.value = "";
                bankAcctRef.current.value = "";
                bankNumRef.current.value = "";
            }else{
                ShowAlert("Failed", "Error found when adding new bank details.");
            }
        })
        e.preventDefault();
    }

    const deleteBankAccount = (id) => {
        const c = window.confirm("Are you sure to delete the selected bank details?");
        if(c) {
            DeleteBankAccount({ bankid: id }, res => {
                if(res.t === "Success") {
                    renderBankAccounts();
                }else{
                    ShowAlert("Failed", "Error found when deleting bank details.");
                }
            })
        }
    }

    const updateOtherSettings = (e) => {
        UpdateSettings({
            adminfee: parseFloat(adminFeeRef.current.value) / 100,
            otherfee: parseFloat(otherFeeRef.current.value) / 100,
            shippingfee: shippingFeeRef.current.value,
            safety: safetyRef.current.value,
            mrp: mrpRef.current.value,
            storediscount: parseFloat(storeDiscountRef.current.value) / 100,
            storepackdisc: storePackDiscRef.current.value,
            cashierdaily: cashierDailyRef.current.value,
        }, res => {
            if(res.t === "Success") {
                ShowAlert("Success", "Settings was successfully saved!");
            }else{
                ShowAlert("Failed", "Error found when saving changes.");
            }
        })
        e.preventDefault();
    }

    useEffect(() => {
        AdminGetAllCashiers(res => {
            setCashiers(res);
        });

        GetAllBankAccounts({ mid: 0 }, res => {
            setBanks(res);
        });

        GetAdminSettings(res => {
            setOther(res);
        });
    }, [])

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen p-8 bg-white overflow-y-auto">
                <h1 className="text-2xl">Settings</h1>
                <hr className="my-4" />
                <div>
                    <h2 className="text-lg">List of Cashiers</h2>
                    <form onSubmit={ (e) => { addCashier(e); } } className="flex my-2">
                        <input ref={ cashierNameRef } type="text" placeholder="Cashier Name" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                        <input ref={ cashierEmailRef } type="email" placeholder="Cashier Email" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                        <input ref={ cashierPasswordRef } type="password" placeholder="Password" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                        <button type="submit" className={ Themes.HeaderButton }>+ Add</button>
                    </form>
                    <table className="w-full text-sm my-2">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Created</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                cashiers.length === 0 ?
                                <tr><td colSpan={ 4 } className="text-center p-4">There are no records to display</td></tr> :
                                cashiers.map(c => (
                                    <tr key={ c.mid + "_cashier_row" }>
                                        <td>{ c.name }</td>
                                        <td>{ c.email }</td>
                                        <td>{ moment(c.created).format("MMM DD, YYYY") }</td>
                                        <td className="text-center"><img src={ xIcon } onClick={ () => { deleteCashier(c.mid); } } className={ Themes.ImageButton } alt="xicon" /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <hr className="my-4" />
                <div>
                    <h2 className="text-lg">Bank Accounts / E-Wallet</h2>
                    <form onSubmit={ (e) => { addBankAccount(e); } } className="flex my-2">
                        <select ref={ bankNameRef } className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required>
                            <option value="">Select Bank Name</option>
                            <option value="BDO">BDO</option>
                            <option value="BPI">BPI</option>
                            <option value="GCash">GCash</option>
                            <option value="Metrobank">Metrobank</option>
                            <option value="Paypal">Paypal</option>
                            <option value="Security Bank">Security Bank</option>
                        </select>
                        <input ref={ bankAcctRef } type="text" placeholder="Account Name" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                        <input ref={ bankNumRef } type="text" placeholder="Account Number" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg ml-2" required />
                        <button type="submit" className={ Themes.HeaderButton }>+ Add</button>
                    </form>
                    <table className="w-full text-sm my-2">
                        <thead>
                            <tr>
                                <th>Bank/E-Wallet Name</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                banks.length === 0 ?
                                <tr><td colSpan={ 4 } className="text-center p-4">There are no records to display</td></tr> :
                                banks.map(b => (
                                    <tr key={ b.bankname + "_row" }>
                                        <td>{ b.bankname }</td>
                                        <td>{ b.acctname }</td>
                                        <td>{ b.acctnum }</td>
                                        <td className="text-center"><img src={ xIcon } onClick={ () => { deleteBankAccount(b.bankid); } } className={ Themes.ImageButton } alt="xicon" /></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                <hr className="my-4" />
                <div>
                    {
                        other !== null ? 
                            <form onSubmit={ (e) => { updateOtherSettings(e); } } className="my-2">
                                <h2 className="text-lg">Fees and Miscellaneous</h2>
                                <div className="flex my-4">
                                    <div>
                                        <h5 className="text-sm"><b>Admin Fee</b> for Payout (%)</h5>
                                        <input ref={ adminFeeRef } type="number" defaultValue={ parseFloat(other.adminfee) * 100 } placeholder="%" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                    <div className="mx-2">
                                        <h5 className="text-sm"><b>Shipping Fee</b> (Amount)</h5>
                                        <input ref={ shippingFeeRef } type="number" defaultValue={ other.shippingfee } placeholder="Amount" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                    <div>
                                        <h5 className="text-sm"><b>Admin Fee</b> for purchasing using Wallet Balance (%)</h5>
                                        <input ref={ otherFeeRef } type="number" defaultValue={ parseFloat(other.otherfee) * 100 } placeholder="%" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                </div>
                                <div className="flex my-4">
                                    <div>
                                        <h5 className="text-sm"><b>Safety Net</b> (Amount)</h5>
                                        <input ref={ safetyRef } type="number" defaultValue={ other.safety } placeholder="Amount" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                    <div className="mx-2">
                                        <h5 className="text-sm"><b>Minimum Renew Points</b> (Points)</h5>
                                        <input ref={ mrpRef } type="number" defaultValue={ other.mrp } placeholder="Points" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                    
                                </div>
                                <div className="flex my-4">
                                    <div>
                                        <h5 className="text-sm"><b>Stockist Repeat Discount</b> (%)</h5>
                                        <input ref={ storeDiscountRef } type="number" defaultValue={ parseFloat(other.storediscount) * 100 } placeholder="%" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                    <div className="mx-2">
                                        <h5 className="text-sm"><b>Stockist Package Discount</b> (Amount)</h5>
                                        <input ref={ storePackDiscRef } type="number" defaultValue={ other.storepackdisc } placeholder="Amount" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                </div>
                                <h2 className="text-lg">Other Settings</h2>
                                <div className="flex my-4">
                                    <div>
                                        <h5 className="text-sm"><b>Cashier Daily Limit Transaction</b> (Count)</h5>
                                        <input ref={ cashierDailyRef } type="number" defaultValue={ other.cashierdaily } placeholder="Count" className="w-48 h-10 border-2 text-sm border-amber-600 px-2 py-1 rounded-lg" required />
                                    </div>
                                </div>
                                <div className="text-right my-4">
                                    <button type="submit" className={ Themes.HeaderButton }>Save Changes</button>
                                </div>
                            </form>
                        : null
                    }
                </div>
            </div>
        </div>
    )
}