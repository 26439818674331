import { useSpring, animated  } from "@react-spring/web";
import moment from "moment";
import { Themes } from "../../../common/Constant";
import { AdminAddPackage, AdminUpdatePackage, GetAdminProductInventory } from "../../../common/Request";
import { ShowAlert } from "../../UI/Alert";
import { useRef, useState, useEffect } from "react";
import bagIcon from "../../../assets/img/shopping-bag.svg";
import priceIcon from "../../../assets/img/price.svg";
import pointsIcon from "../../../assets/img/points.svg";
import percentIcon from "../../../assets/img/percent.svg";
import closeIcon from "../../../assets/img/white-x.svg";

export const PackageForm = ({ isAdd = false, data = {}, show = false, hide }) => {

    const [ products, setProducts ] = useState([]);
    const [ prids, setPrids ] = useState([]);
    const nameRef = useRef();
    const totalRef = useRef();
    const pointsRef = useRef();
    const storeMinRef = useRef();

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const changeProductList = (e, index) => {
        const prid = parseInt(products[index].prid);
        let arr = prids;
        let exist = false;
        arr.forEach(p => {
            if(parseInt(p.prid) === prid) {
                exist = true;
                p.qty = e.target.value;
            }
        });
        if(!exist) {
            arr.push({ qty: e.target.value, prid: prid });
        }
        setPrids(arr);
    }

    const onSubmit = (e) => {
        if(isAdd) {
            AdminAddPackage({
                name: nameRef.current.value,
                prids: JSON.stringify(prids),
                total: totalRef.current.value,
                points: pointsRef.current.value,
                storemin: storeMinRef.current.value
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "New package was added.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }else{
            AdminUpdatePackage({
                paid: data.paid,
                name: nameRef.current.value,
                prids: JSON.stringify(prids),
                total: totalRef.current.value,
                points: pointsRef.current.value,
                storemin: storeMinRef.current.value
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "Changes was saved.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }
        e.preventDefault();
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
        nameRef.current.value = data.name || '';
        totalRef.current.value = data.total || '';
        pointsRef.current.value = data.points || '';
        storeMinRef.current.value = data.storemin || '';
        if(data.prids) {
            setPrids(JSON.parse(data.prids) || []);
        }
        const products = data.prids !== undefined ? JSON.parse(data.prids) : [];
        GetAdminProductInventory(res => {
            res.forEach((r, i) => {
                let exist = false;
                products.forEach(product => {
                    if(parseInt(r.prid) === parseInt(product.prid)) {
                        exist = true;
                        r.value = product.qty;
                    }
                });
                if(!exist) {
                    r.value = 0;
                }
                r.id = moment().format("x") + "_" + i;
            });
            setProducts(res);
        })
    }, [ show, data, anim ])

    return (
        <div className={ "fixed inset-0 w-screen h-screen bg-slate-700/50 overflow-y-auto p-2 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    {
                        isAdd ? "Add New Package" : "Edit Package"
                    }
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2">
                    { !isAdd ? <h5 className="text-sm font-bold">Package Name:</h5> : null }
                    <div className="relative">
                        <img src={ bagIcon } className={ Themes.InputIcon } alt="bagicon" />
                        <input ref={ nameRef } type="text" className={ Themes.InputField } placeholder="Package Name" required />
                    </div>
                    <h5 className="text-sm font-bold">Products Included:</h5>
                    <div className="relative">
                        {
                            products.map((p, i) => (
                                <div className="mb-1 text-sm" key={ p.id }>
                                    Qty. 
                                    <input type="number" onChange={ (e) => { changeProductList(e, i) } } className="w-12 border-2 border-slate-700 text-slate-700 text-center font-bold rounded-sm mx-2" 
                                        step="1" min="0" defaultValue={ p.value } /> 
                                    &times; { p.name }
                                </div>
                            ))
                        }
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Price:</h5> : null }
                    <div className="relative">
                        <img src={ priceIcon } className={ Themes.InputIcon } alt="priceicon" />
                        <input ref={ totalRef } type="number" inputMode="decimal" className={ Themes.InputField } placeholder="Price" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Points:</h5> : null }
                    <div className="relative">
                        <img src={ pointsIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input ref={ pointsRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Points" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Min. Qty. for Stockist Discount:</h5> : null }
                    <div className="relative">
                        <img src={ percentIcon } className={ Themes.InputIcon } alt="percenticon" />
                        <input ref={ storeMinRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Min. Qty. for Stockist Discount" required />
                    </div>
                    <button type="submit" className={ Themes.FilledButton }>{ isAdd ? "Submit" : "Save Changes" }</button>
                </form>
            </animated.div>
        </div>
    )

}