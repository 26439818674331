import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Themes } from "../../../common/Constant";
import { formatMoney } from "../../../common/Utils";
import { GetAdminProductInventory } from "../../../common/Request";
import { ProductForm } from "../form/ProductForm";
import searchIcon from "../../../assets/img/search.svg";
import editIcon from "../../../assets/img/edit.svg";

export const ProductTable = () => {

    const [ details, setDetails ] = useState({});
    const [ showDetails, setShowDetails ] = useState(false);
    const [ adding, setAdding ] = useState(false);

    const addProduct = () => {
        setDetails({name: null, description: null, price: null, points: null, photo: null});
        setShowDetails(true);
        setAdding(true);
    }

    const showProductDetails = (d) => {
        setDetails(d);
        setShowDetails(true);
        setAdding(false);
    }

    const hideProductDetails = (reload = false) => {
        setShowDetails(false);
        if(reload) {
            GetAdminProductInventory(res => {
                setData(res);
            });
        }
    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => formatMoney(row.price),
            sortable: true,
        },
        {
            name: 'Points',
            selector: row => row.points,
            sortable: true,
        },
        {
            name: 'Free Shipping',
            selector: row => row.free,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => <b style={{ color: row.status === "ACTIVE" ? "green" : "red" }}>{ row.status }</b>,
        },
        {
            name: 'Action',
            selector: row => <img src={ editIcon } onClick={ () => { showProductDetails(row); } } className={ Themes.ImageButton } alt="editicon" />
        },
        {
            name: 'Updated',
            selector: row => row.updated,
        },
    ];

    const [ data, setData ] = useState([]);

    const [filterText, setFilterText] = useState('');
    const filteredItems = data.filter(
		item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
	);

    const titleComponent = useMemo(() => {
        return (
            <div>
                <h1 className="inline-block">Product List</h1>
                <button type="button" className={ Themes.HeaderButton } onClick={ () => { addProduct() } }>+ Product</button>
            </div>
        )
    }, []);

    const subHeaderComponentMemo = useMemo(() => {
		return (
            <div>
                <div className="relative">
                    <img src={ searchIcon } className={ Themes.InputIcon } alt="searchicon" />
                    <input type="text" className={ Themes.InputField } placeholder="Filter By Name" value={ filterText } onChange={ (e) => { setFilterText(e.target.value) } } />
                </div>
            </div>
		);
	}, [filterText]);

    useEffect(() => {
        GetAdminProductInventory(res => {
            setData(res);
        });
    }, []);

    return (
        <div className="w-full mb-4">
            <DataTable
                title={ titleComponent }
                pagination
                columns={ columns }
                data={ filteredItems }
                subHeader
                subHeaderComponent={ subHeaderComponentMemo }
            />
            <ProductForm isAdd={ adding } data={ details } show={ showDetails } hide={ hideProductDetails } />
        </div>
        
    )
}