import { useSpring, animated  } from "@react-spring/web";
import { dispatch, useStoreState } from "../../common/Store";
import { Themes } from "../../common/Constant";
import { useEffect } from "react";

export const ShowAlert = (title, message) => {
    dispatch({ type: "alert", 
        data: {
            show: true,
            title: title,
            message: message
        } 
    });
}

export const Alert = () => {

    const alert = useStoreState("alert");
    const show = alert.show;

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const click = () => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                dispatch({ type: "alert", 
                    data: {
                        show: false,
                        title: null,
                        message: null
                    } 
                });
            }
        })
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
    }, [ show, anim ]);

    return (
        <div className={ "fixed left-0 top-0 w-screen h-screen z-40 bg-black/25 " + (show ? "visible" : "invisible") }>
            <animated.div className="absolute inset-0 bg-white max-w-80 max-h-44 m-auto" style={ animProps }>
                <header className="bg-gray-600 text-white px-4 py-2 text-lg">{ alert.title }</header>
                <p className="px-4 py-2">{ alert.message }</p>
                <footer className="absolute -bottom-2 right-0 w-36 p-2">
                    <button type="button" className={ Themes.FilledButton } onClick={ () => { click(); } }>Okay</button>
                </footer>
            </animated.div>
        </div>
    )

}