import { dispatch } from "../common/Store";
import { Themes } from "../common/Constant";
import { parseURLParam } from "../common/Utils";
import { CreateMember } from "../common/Request";
import { ShowAlert } from "../component/UI/Alert";
import logo from "../assets/img/logo.png";
import userIcon from "../assets/img/user.svg";
import lockIcon from "../assets/img/lock.svg";
import locationIcon from "../assets/img/location.svg";
import emailIcon from "../assets/img/email.svg";
import phoneIcon from "../assets/img/phone.svg";
import sponsorIcon from "../assets/img/sponsor.svg";
import uplineIcon from "../assets/img/upline.svg";
import leftRightIcon from "../assets/img/left-right.svg";
import { useEffect, useRef } from "react";

export const Register = () => {

    const codeRef = useRef();
    const uplineRef = useRef();
    const posRef = useRef();
    const nameRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const addressRef = useRef();
    const passRef = useRef();
    const rePassRef = useRef();

    const onSubmit = (e) => {
        if(passRef.current.value !== rePassRef.current.value) {
            ShowAlert("Failed", "Passwords did not match.");
        }else{
            CreateMember({
                sponsor: codeRef.current.value,
                upline: uplineRef.current.value,
                position: posRef.current.value,
                name: nameRef.current.value,
                phone: phoneRef.current.value,
                email: emailRef.current.value,
                address: addressRef.current.value,
                password: passRef.current.value,
            }, res => {
                if(res.t !== "Success") {
                    ShowAlert(res.t, res.m);
                }else{
                    ShowAlert("Registration", "Creating an account was a success.");
                    dispatch({ type: "page", data: "login" });
                }
            })
        }
        e.preventDefault();
    }

    useEffect(() => {
        const param = parseURLParam();
        if(param['ref']) codeRef.current.value = param['ref'];
    }, []);

    return (
        <div className="bg-white max-w-96 w-screen block m-auto px-4 py-2 drop-shadow-lg">
            <img src={ logo } className="block w-24 h-24 p-2 m-auto mb-2" alt="logo" />
            <h1 className="text-lg">SIGN UP</h1>
            <form onSubmit={ (e) => { onSubmit(e); } }>
                <div className="relative">
                    <img src={ sponsorIcon } className={ Themes.InputIcon } alt="spoicon" />
                    <input ref={ codeRef } type="text" className={ Themes.InputField } placeholder="Sponsor Code" required />
                </div>
                <div className="relative">
                    <img src={ uplineIcon } className={ Themes.InputIcon } alt="upicon" />
                    <input ref={ uplineRef } type="text" className={ Themes.InputField } placeholder="Upline Code" required />
                </div>
                <div className="relative mb-4">
                    <img src={ leftRightIcon } className={ Themes.InputIcon } alt="lricon" />
                    <select ref={ posRef } className={ Themes.InputField } required>
                        <option value="" hidden>Position</option>
                        <option value="Left">Left</option>
                        <option value="Right">Right</option>
                    </select>
                </div>
                <div className="relative">
                    <img src={ userIcon } className={ Themes.InputIcon } alt="usericon" />
                    <input ref={ nameRef } type="text" className={ Themes.InputField } placeholder="Full Name" required />
                </div>
                <div className="relative">
                    <img src={ phoneIcon } className={ Themes.InputIcon } alt="phoneicon" />
                    <input ref={ phoneRef } type="text" className={ Themes.InputField } placeholder="Phone Number" required />
                </div>
                <div className="relative">
                    <img src={ emailIcon } className={ Themes.InputIcon } alt="emailicon" />
                    <input ref={ emailRef } type="email" className={ Themes.InputField } placeholder="Email Address" required />
                </div>
                <div className="relative">
                    <img src={ locationIcon } className={ Themes.InputIcon } alt="locicon" />
                    <input ref={ addressRef } type="text" className={ Themes.InputField } placeholder="Street, Barangay, City, State, Country" required />
                </div>
                <div className="relative mb-2">
                    <img src={ lockIcon } className={ Themes.InputIcon } alt="lockicon" />
                    <input ref={ passRef } type="password" className={ Themes.InputField } placeholder="Password" required />
                </div>
                <div className="relative mb-2">
                    <img src={ lockIcon } className={ Themes.InputIcon } alt="lockicon" />
                    <input ref={ rePassRef } type="password" className={ Themes.InputField } placeholder="Confirm Password" required />
                </div>
                <button type="submit" className={ Themes.FilledButton }>Submit</button>
                <button type="button" className={ Themes.OutlineButton } onClick={ () => { dispatch({ type: "page", data: "login" }); } }>Cancel</button>
            </form>
        </div>
    )

}