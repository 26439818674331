import moment from "moment";
import { useEffect, useState } from "react";
import { GetProducts, GetDiscount, AddCart } from "../../common/Request";
import { useStoreState, dispatch } from "../../common/Store";
import { formatMoney } from "../../common/Utils";
import { Themes } from "../../common/Constant";
import { Navigation } from "./Navigation";
import { MemberCart } from "./MemberCart";
import photo from "../../assets/img/image.svg";
import cart from "../../assets/img/shopping-cart.svg";

export const MemberProducts = () => {

    const userInfo = useStoreState("userinfo");
    const [ data, setData ] = useState([]);
    const [ discount, setDiscount ] = useState(0);

    const addCart = (prid, free, price, name, points) => {
        AddCart({ mid: userInfo.mid, prid: prid, free: free, price: price, name: name, points: points }, res => {
            dispatch({ type: "updatecart", data: moment().format("x") });
        })
    }

    useEffect(() => {
        GetProducts(res => {
            setData(res);
        })
        GetDiscount({ mid: userInfo.mid }, res => {
            setDiscount(parseFloat(res.discount));
        })
        
    }, [ userInfo ])

    return (
        <div>
            <Navigation />
            <MemberCart />
            <div className="grid gap-4 grid-cols-2 p-4 pb-20 lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-2">
                {
                    data.map(d => (
                        <div className="bg-slate-100 pb-2" key={ "product_" + d.prid }>
                            <img src={ d.photo ? d.photo : photo } className="w-full h-48 bg-white object-contain" alt="productphoto" />
                            <h5 className="text-slate-700 text-lg px-2 font-bold">{ d.name }</h5>
                            <h5 className="text-green-600 text-xs px-2">Free shipping every { d.free } qty</h5>
                            <h5 className="px-2 text-red-600 italic">
                                <span className="line-through">&#8369;{ formatMoney(d.price) }</span>
                                <span className="float-right">-{ (discount * 100) }%</span>
                            </h5>
                            <h5 className="text-amber-600 px-2 text-2xl font-bold mb-2">&#8369;{ formatMoney(parseFloat(d.price) - (parseFloat(d.price) * discount)) }</h5>
                            <button type="button" className={ Themes.HeaderButton + " scale-75 mx-auto block" } onClick={ () => { addCart(d.prid, d.free, d.price, d.name, d.points); } }>
                                <img src={ cart } className={ Themes.HeaderButtonImage } alt="carticon" />
                                Add to Cart
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}