import moment from "moment";
import DataTable from "react-data-table-component";
import { formatMoney } from "../../../common/Utils";
import { MemberMyStore, MemberMyStoreInventory, MemberMyStoreTransactions } from "../../../common/Request";
import { TabPanel } from "../../UI/TabPanel";
import { useEffect, useState } from "react";
import xIcon from "../../../assets/img/x-mark.svg";

export const StoreModal = ({ show = false, mid = 0, onClose }) => {

    const [ front, setFront ] = useState(false);
    const [ data, setData ] = useState({name: "", address: "", obj: []});
    const [ inventory, setInventory ] = useState([]);
    const [ transactions, setTransactions ] = useState([]);
    const [ tabIndex, setTabIndex ] = useState(0);

    useEffect(() => {
        MemberMyStore({ mid: mid }, res => {
            setData(res);
        });

        MemberMyStoreInventory({ mid: mid }, res => {
            setInventory(res);
        });

        MemberMyStoreTransactions({ mid: mid }, res => {
            setTransactions(res)
        });

        setTimeout(() => {
            setFront(show);
        }, show ? 0 : 300);

    }, [ show, mid ])

    return (
        <div data-front={ front } className="fixed left-0 top-0 w-screen h-screen z-20 p-10 bg-slate-700/75 overflow-y-auto data-[front=false]:invisible">
            <div data-show={ show } className="transition ease-linear duration-300 w-full max-w-[800px] mx-auto data-[show=false]:opacity-0 data-[show=false]:-translate-y-1/4">
                <div className="bg-gradient-to-r from-teal-400 to-teal-600 py-2 px-4">
                    <h1 className="text-white text-xl inline">{ data.name }</h1>
                    <img src={ xIcon } className="invert w-8 float-right cursor-pointer" alt="xicon" onClick={ () => { onClose() } } />
                </div>
                <div className="bg-teal-50 p-4">
                    <TabPanel tabs={ ['Inventory', 'Transactions'] } onChange={ (index) => { setTabIndex(index) } } />
                    <br />
                {
                    tabIndex === 0 ?
                    <div>
                        <h1 className="text-slate-700 text-lg">Inventory Status List</h1>
                        <div>
                            <DataTable
                                pagination
                                columns={ [
                                    { name: 'Item / Package Name', selector: row => <div>{ row.name }{ row.type === "Package" ? " Package" : null }</div> },
                                    { name: 'Price', selector: row => <div>&#8369; { formatMoney(row.price) }</div>, sortable: true },
                                    { name: 'Stock', selector: row => row.stock, sortable: true }
                                ] }
                                data={ inventory }
                            />
                        </div>
                    </div>
                    :
                    <div>
                        <h1 className="text-slate-700 text-lg">Transaction History</h1>
                        <div>
                            <DataTable
                                pagination
                                columns={ [
                                    { name: 'Date Created', selector: row => moment(row.updated).format("MMM DD, YYYY"), sortable: true },
                                    { name: 'Ref #', selector: row => row.ref },
                                    { name: 'Name', width: '150px', selector: row => row.name, sortable: true },
                                    { 
                                        name: 'Type',
                                        width: '150px',
                                        selector: row => <div><b className="text-amber-600">
                                            {
                                                row.renew === 1 ? "Account Renewal" : row.paid > 0 ? "Package Purchase" : "Repeat Purchase"
                                            }
                                        </b></div>
                                    },
                                    { name: 'Total', selector: row => <div>&#8369; { formatMoney(row.total) }</div>, sortable: true },
                                ] }
                                data={ transactions }
                            />
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}