export const LabelBox = ({ className, title, body }) => {

    return (
        <div className={ className }>
            <div className={ "bg-white text-center py-4 cursor-pointer" }>
                <div className={ "text-slate-700 font-bold mb-2" }>{ title }</div>
                <div className={ "text-sky-600 font-bold text-4xl" }>{ body }</div>
            </div>
        </div>
    )
}