import { useSpring, animated  } from "@react-spring/web";
import { Themes } from "../../../common/Constant";
import { AdminAddProduct, AdminUpdateProduct } from "../../../common/Request";
import { ShowAlert } from "../../UI/Alert";
import { useRef, useState } from "react";
import pouchIcon from "../../../assets/img/pouch.svg";
import infoIcon from "../../../assets/img/info.svg";
import priceIcon from "../../../assets/img/price.svg";
import pointsIcon from "../../../assets/img/points.svg";
import photoIcon from "../../../assets/img/photo.svg";
import truckIcon from "../../../assets/img/truck.svg";
import percentIcon from "../../../assets/img/percent.svg";
import closeIcon from "../../../assets/img/white-x.svg";
import { useEffect } from "react";

export const ProductForm = ({ isAdd = false, data = {}, show = false, hide }) => {

    const nameRef = useRef();
    const descRef = useRef();
    const priceRef = useRef();
    const pointsRef = useRef();
    const freeRef = useRef();
    const storeMinRef = useRef();
    const photoRef = useRef();
    const [ photo, setPhoto ] = useState(null);

    const [ animProps, anim ] = useSpring(
        () => ({ 
            from: { y: -20, opacity: 0 },
            to: { y: 0 , opacity: 1 },
            config: {
                duration: 150
            }
        }), 
    []);

    const close = (reload = false) => {
        anim.start({
            to: { y: -20, opacity: 0 },
            onResolve: () => {
                hide(reload);
            }
        })
    }

    const onSubmit = (e) => {
        if(isAdd) {
            AdminAddProduct({
                name: nameRef.current.value,
                description: descRef.current.value,
                price: priceRef.current.value,
                points: pointsRef.current.value,
                storemin: storeMinRef.current.value,
                free: freeRef.current.value,
                photo: photoRef.current.files[0]
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "New product was added.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }else{
            AdminUpdateProduct({
                prid: data.prid,
                name: nameRef.current.value,
                description: descRef.current.value,
                price: priceRef.current.value,
                points: pointsRef.current.value,
                storemin: storeMinRef.current.value,
                free: freeRef.current.value,
                photo: photoRef.current.files[0]
            }, res => {
                if(res.t === "Success") {
                    ShowAlert("Success", "Changes was saved.");
                    close(true);
                }else{
                    ShowAlert(res.t, res.m);
                }
            });
        }
        e.preventDefault();
    }

    const onChangePhoto = (e) => {
        const reader = new FileReader();
        reader.onload = function(evt)  {
            setPhoto(evt.target.result);
         }
         reader.readAsDataURL(e.target.files[0]);
    }

    useEffect(() => {
        if(show) {
            anim.start({
                to: { y: 0, opacity: 1 }
            })
        }
        if(data.photo !== null) {
            setPhoto(data.photo);
        }
        nameRef.current.value = data.name;
        descRef.current.value = data.description;
        priceRef.current.value = data.price;
        pointsRef.current.value = data.points;
        storeMinRef.current.value = data.storemin;
        freeRef.current.value = data.free;
    }, [ show, data, anim ])

    return (
        <div className={ "fixed z-50 inset-0 w-screen h-screen bg-slate-700/50 overflow-y-auto p-2 " + (show ? "visible" : "invisible") }>
            <animated.div className="max-w-96 w-screen m-auto bg-white drop-shadow-lg" style={ animProps }>
                <h1 className={ Themes.Header }>
                    {
                        isAdd ? "Add New Product" : "Edit Product"
                    }
                    <img src={ closeIcon } className="absolute top-1 right-2 w-8 cursor-pointer" onClick={ () => { close(); } } alt="closeicon" />    
                </h1>
                <form onSubmit={ (e) => { onSubmit(e); } } className="p-2">
                    { !isAdd ? <h5 className="text-sm font-bold">Product Name:</h5> : null }
                    <div className="relative">
                        <img src={ pouchIcon } className={ Themes.InputIcon } alt="pouchicon" />
                        <input ref={ nameRef } type="text" className={ Themes.InputField } placeholder="Product Name" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Description:</h5> : null }
                    <div className="relative">
                        <img src={ infoIcon } className={ Themes.InputIcon } alt="infoicon" />
                        <textarea ref={ descRef } className={ Themes.InputField + " h-20 resize-none" } placeholder="Description" ></textarea>
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Price:</h5> : null }
                    <div className="relative">
                        <img src={ priceIcon } className={ Themes.InputIcon } alt="priceicon" />
                        <input ref={ priceRef } type="number" inputMode="decimal" className={ Themes.InputField } placeholder="Price" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Points:</h5> : null }
                    <div className="relative">
                        <img src={ pointsIcon } className={ Themes.InputIcon } alt="pointsicon" />
                        <input ref={ pointsRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Points" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Min. Qty. for Stockist Discount:</h5> : null }
                    <div className="relative">
                        <img src={ percentIcon } className={ Themes.InputIcon } alt="percenticon" />
                        <input ref={ storeMinRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Min. Qty. for Stockist Discount" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Free Shipping Minimum:</h5> : null }
                    <div className="relative">
                        <img src={ truckIcon } className={ Themes.InputIcon } alt="truckicon" />
                        <input ref={ freeRef } type="number" inputMode="numeric" step="1" className={ Themes.InputField } placeholder="Free Shipping Minimum" required />
                    </div>
                    { !isAdd ? <h5 className="text-sm font-bold">Product Photo:</h5> : null }
                    <div className="relative">
                        <img src={ photoIcon } className={ Themes.InputIcon } alt="photoicon" />
                        <input ref={ photoRef } type="file" accept="image/*" onChange={ (e) => { onChangePhoto(e) } } className={ Themes.InputField } placeholder="Product Photo" />
                    </div>
                    {
                        photo ? <img src={ photo } className="w-32 h-32 m-auto mb-4" alt="previewproduct" /> : null
                    }
                    <button type="submit" className={ Themes.FilledButton }>{ isAdd ? "Submit" : "Save Changes" }</button>
                </form>
            </animated.div>
        </div>
    )

}