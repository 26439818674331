import { PurchaseTable } from "./tables/PurchaseTable";
import { Navigation } from "./Navigation";

export const AdminPackagePurchase = () => {

    return (
        <div className="relative flex">
            <Navigation />
            <div className="w-full h-screen overflow-y-auto p-8">
                <PurchaseTable />
            </div>
        </div>
    );
}